<template>
  <input class="input-text m-tx4 w-30p" placeholder="Title (optional)" v-model="title">
  <div class="bill-splitter-body m-tx4">
    <div class="f-v w-x76">
      <div class="f-sb">
        <input class="input-text w-x60" placeholder="Enter name..." v-model="personName" @keyup.enter="addPerson" @keydown.tab.prevent="onItemNameTab">
        <button :class="`button ${canAddPerson ? '' : 'disabled'}`" @click="addPerson"> Add </button>
      </div>
      <BillSplitterPerson
        v-for="(_person, idx) in billSplitterStore.selectedTab.people"
        :key="`person-${idx}`"
        :person-idx="idx"
        class="m-tx2"
      />
    </div>
    <div class="f-v w-x76 m-lx8">
      <div class="f-sb">
        <input class="input-text w-x40" placeholder="Enter item..." v-model="itemName" ref="itemNameEl">
        <input type="number" class="input-text w-x16" placeholder="Price..." v-model="itemPrice" @keyup.enter="onItemPriceEnter" @keydown.tab="onItemPriceTab">
        <button :class="`button ${canAddItem ? '' : 'disabled'}`" @click="addItem"> Add </button>
      </div>
      <BillSplitterItem
        v-for="(_item, idx) in billSplitterStore.selectedTab.items"
        :key="`item-${idx}`"
        :item-idx="idx"
        class="m-tx2"
      />
    </div>
    <div class="f-v w-x76 m-lx8">
      <div class="f-sb f-ac">
        <div class="t-16">Total Spent: </div>
        <input type="number" class="input-text" v-model="totalSpent" ref="totalSpentEl">
      </div>
      <BillSplitterBreakdown class="m-tx2" />
    </div>
  </div>
</template>

<script setup>
  import { ref, computed } from 'vue';
  import { useBillSplitterStore } from '../../stores/bill_splitter_store.js';

  const billSplitterStore = useBillSplitterStore();

  // Title
  const title = computed({
    get: () => billSplitterStore.selectedTab.title,
    set: (value) => billSplitterStore.updateTabValue({ key: 'title', value })
  });


  // People
  const personName = ref('');
  const canAddPerson = computed(() => personName.value.trim().length > 0);
  const addPerson = () => {
    if (canAddPerson.value) {
      billSplitterStore.addPerson(personName.value.trim());
      personName.value = '';
    }
  };

  // Items
  const itemName = ref('');
  const itemPrice = ref(0);
  const canAddItem = computed(() => itemName.value.trim().length > 0 && itemPrice.value > 0);
  const addItem = () => {
    if (canAddItem.value) {
      billSplitterStore.addItem(itemName.value.trim(), itemPrice.value);
      itemName.value = '';
      itemPrice.value = 0;
    }
  };

  // Total
  const totalSpent = computed({
    get: () => billSplitterStore.selectedTab.totalSpent,
    set: (value) => billSplitterStore.updateTabValue({ key: 'totalSpent', value }),
  });

  // Navigation
  const itemNameEl = ref(null);
  const totalSpentEl = ref(null);
  const onItemNameTab = () => {
    itemNameEl.value.focus();
  };
  const onItemPriceEnter = () => {
    addItem();
    itemNameEl.value.focus();
  };
  const onItemPriceTab = () => {
    totalSpentEl.value.focus();
  };
</script>