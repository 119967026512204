<template>
  <div class="t-28 fw-7 onyx">Select Movie</div>
  <BaseBreadcrumbs :crumbs="[{ path: '/movies', label: 'Movie Club Log' }]" class="m-tx4" />
  <div :class="`m-tx8 ${isMobile ? 'f-v' : 'f'}`">
    <div :class="`pos-rel m-rx2 ${isMobile ? 'm-bx2' : ''}`">
      <span class="material-symbols-outlined pos-abs left-4 top-4"> search </span>
      <input class="input-text w-x50 p-lx8" v-model="titleQuery" placeholder="Title">
    </div>
    <div :class="`pos-rel m-rx2 ${isMobile ? 'm-bx2' : ''}`">
      <span class="material-symbols-outlined pos-abs left-4 top-4"> calendar_today </span>
      <input type="number" class="input-text w-x34 p-lx8" v-model="yearQuery" placeholder="Year (optional)">
    </div>
    <button :class="`button w-x15 ${!titleQuery.trim() ? ' disabled' : ''}`" @click="performSearch">Search</button>
  </div>
  <div :class="`f-w bg-dark-gray p-x4 br-8 m-tx8 ${isMobile ? 'w-75p' : 'w-60p'}`">
    <div v-if="results.length === 0" class="t-18 white"> No results... </div>
    <MoviesPoster 
      v-for="result in results"
      :movie="result"
      :is-link="true"
      @click="selectMovie(result)" 
    />
  </div>
</template>

<script setup>
  import { ref } from 'vue';
  import axios from 'axios';
  import { useTmdbStore } from '../../stores/tmdb_store.js';
  import { useScreens } from '../../composables/screens.js';
  const tmdbStore = useTmdbStore();
  const { isMobile } = useScreens();

  const titleQuery = ref('');
  const yearQuery = ref('');
  const performSearch = () => {
    let url = `/external_api/tmdb/search?title=${titleQuery.value}`
    if (yearQuery.value) url += `&year=${yearQuery.value}`
    axios.get(url)
      .then(({ data }) => {
        results.value = data.results;
      })
  };
  const results = ref([]);

  const selectMovie = (movie) => {
    tmdbStore.selectMovie(movie);
  }
</script>