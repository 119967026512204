<template>
  <div class="breakdown">
    <div v-if="!totalSpent" class="t-16 m-x4">No money spent...</div>
    <div v-else-if="!totalPrice" class="t-16 m-x4">Nothing purchased...</div>
    <div v-else class="m-x4">
      <div class="t-16">Total Spent: {{ format(totalSpent) }}</div>
      <div class="t-16 m-tx2">Subtotal: {{ format(totalPrice) }}</div>
      <div class="t-16 m-t4">Tax & Tip: {{ format(totalSpent - totalPrice) }}</div>
      <div class="separator bg-black m-tx3 m-bx3"></div>
      <div v-for="(person, idx) in billSplitterStore.selectedTab.people" :key="`person-${idx}`" class="t-16 m-b4">
        {{ person.name }}: {{ format(amountOwedForPerson(idx)) }}
      </div>
    </div>
  </div>
</template>

<script setup>
  import { computed } from 'vue';

  import { useBillSplitterStore } from '../../stores/bill_splitter_store.js';
  const billSplitterStore = useBillSplitterStore();

  const totalSpent = computed(() => billSplitterStore.selectedTab.totalSpent);
  const totalPrice = computed(() => {
    let total = 0;
    billSplitterStore.selectedTab.people.forEach((person) => {
      person.items.forEach((item) => {
        const price = item.price * item.perc;
        total += price;
      });
    });
    return total;
  });

  const shareOfTotalPriceForPerson = (personIdx) => {
    const total = billSplitterStore.totalForPerson(personIdx);
    return total / totalPrice.value;
  };
  const amountOwedForPerson = (idx) => {
    return shareOfTotalPriceForPerson(idx) * totalSpent.value;
  };

  const format = (num) => {
    return billSplitterStore.currencyFormat.format(num);
  }
</script>