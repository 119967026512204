<template>
  <div class="t-28 fw-7 onyx">Movie Club Log</div>
  <div 
    :style="{ width: isMobile ? '70%' : `${sizes.containerWidth}px` }"
    :class="`f-sb f-ac p-x4 bg-dark-gray br-8 m-tx8 ${sizes.shouldVerticalizeControls ? 'f-v': ''}`"
  >
    <a href="/movies/new" class="button">Log New Movie</a>
    <div :class="`f-ac ${sizes.shouldVerticalizeControls ? 'f-v': ''}`">
      <div class="t white m-r4">Search</div>
      <div class="pos-rel m-rx4">
        <span class="material-symbols-outlined pos-abs left-4 top-4"> search </span>
        <input class="input-text w-x50 p-lx8" v-model="filterQuery" placeholder="Filter by title...">
      </div>
      <div class="t white m-r4">Sort by</div>
      <select class="input-select" v-model="orderSelection">
        <option v-for="option in orderOptions" :value="option.params">{{ option.name }}</option>
      </select>
    </div>
  </div>
  <div 
    :style="{ width: isMobile ? '70%' : `${sizes.containerWidth}px` }"
    :class="`p-x4 bg-dark-gray br-8 m-tx4 ${sizes.shouldVerticalizeControls ? 'f-v': ''}`"
  >
    <div class="t-16 white">Filter by</div>
    <div class="m-tx4">
      <div class="f">
        <div class="t white m-rx2 f-as c-p" @click="setFilterDisplay('proposer')">
          <span>Proposer</span>
          <span class="material-symbols-outlined t-10 m-rx1" :style="{ 'font-size': '20px' }">
            {{ filterDisplay === 'proposer' ? 'expand_less' : 'expand_more' }} 
          </span>
        </div>
        <div class="t white m-rx2 f-as c-p" @click="setFilterDisplay('original_language')">
          <span>Original Language</span>
          <span class="material-symbols-outlined t-10 m-rx1" :style="{ 'font-size': '20px' }">
            {{ filterDisplay === 'original_language' ? 'expand_less' : 'expand_more' }} 
          </span>
        </div>
      </div>
      <div v-if="filterDisplay === 'proposer'" class="f-w m-tx3">
        <div v-for="(proposer, idx) in proposerFilterOptions" class="f-ac m-rx3 m-bx2">
          <input type="checkbox" class="input-checkbox small m-r4" :id="`proposer-${idx}`" v-model="proposer.checked">
          <label :for="`proposer-${idx}`" class="input-label white t-12">{{ proposer.label }}</label>
        </div>
      </div>
      <div v-else-if="filterDisplay === 'original_language'" class="f-w m-tx3">
        <div v-for="(originalLanguage, idx) in originalLanguageFilterOptions" class="f-ac m-rx3 m-bx2">
          <input type="checkbox" class="input-checkbox small m-r4" :id="`originalLanguage-${idx}`" v-model="originalLanguage.checked">
          <label :for="`originalLanguage-${idx}`" class="input-label white t-12">{{ originalLanguage.label }}</label>
        </div>
      </div>
    </div>
  </div>
  <div class="f-w bg-dark-gray p-x4 br-8 m-tx4" :style="{ width: `${sizes.containerWidth}px` }">
    <MoviesPoster 
      v-for="movie in orderedFilteredMovies"
      :movie="movie"
      :size="`w${sizes.posterWidth}`"
      :href="`/movies/${movie.id}`"
    />
  </div>
</template>

<script setup>
  import { ref, reactive, computed, onMounted } from 'vue';
  import { useScreens } from '../../composables/screens.js';

  const { movies, prefills } = defineProps(['movies', 'prefills']);
  
  // Sizing
  const { screenWidth, isMobile } = useScreens();
  const sizes = computed(() => {
    // Margins between posters are 8px
    // posterWidth is either 92 or 154, but both are rendering at +2 px for somereason
    // (16 * numPosters) + (numPosters * (posterWidth + 2))
    const approxSidebarWidth = isMobile.value ? '0' : '160';
    const posterWidth = isMobile.value ? 92 : 154;
    const maxContainerWidth = (screenWidth.value - approxSidebarWidth) * (isMobile.value ? 0.5 : 0.7);

    let numPosters = 0;
    let containerWidth = 0;
    while (containerWidth < maxContainerWidth) {
      numPosters += 1;
      containerWidth = (16 * numPosters) + (numPosters * (posterWidth + 2));
    }
    return { posterWidth, containerWidth, shouldVerticalizeControls: numPosters < 4 || isMobile.value };
  });

  // // Filtering and Ordering
  const filterQuery = ref('');
  const orderSelection = ref(null);
  const orderOptions = [
    { name: 'Title (asc.)', params: JSON.stringify({ val: 'title', dir: 1 }) },
    { name: 'Title (desc.)', params: JSON.stringify({ val: 'title', dir: -1 }) },
    { name: 'Release Date (asc.)', params: JSON.stringify({ val: 'release_date', dir: 1 }) },
    { name: 'Release Date (desc.)', params: JSON.stringify({ val: 'release_date', dir: -1 }) },
    { name: 'Viewing Date (asc.)', params: JSON.stringify({ val: 'viewing_date', dir: 1 }) },
    { name: 'Viewing Date (desc.)', params: JSON.stringify({ val: 'viewing_date', dir: -1 }) },
  ]
  onMounted(() => {
    orderSelection.value = orderOptions[5].params;
  })
  // Setting Which Filter Options to Show
  const filterDisplay = ref(null);
  const setFilterDisplay = (val) => {
    if (filterDisplay.value === val) {
      filterDisplay.value = null;
    } else {
      filterDisplay.value = val;
    }
  }
  // Filtering on Proposer
  const proposerFilterOptions = reactive(prefills.proposer.map((proposer) => {
    return { label: proposer, checked: false };
  }));
  const selectedProposers = computed(() => {
    return proposerFilterOptions.filter(p => p.checked).map(p => p.label);
  });
  // Filtering on Original Language
  const originalLanguageFilterOptions = reactive(prefills.original_language.map((originalLanguage) => {
    return { label: originalLanguage, checked: false };
  }));
  const selectedOriginalLanguages = computed(() => {
    return originalLanguageFilterOptions.filter(l => l.checked).map(l => l.label);
  });
  // Finally Filtering and Ordering
  const orderedFilteredMovies = computed(() => {
    if (!orderSelection.value) return [];
    // Filter
    const filteredMovies = movies.filter((movie) => {
      const matchesQuery = movie.title.toLowerCase().includes(filterQuery.value.toLowerCase());
      const matchesProposer = selectedProposers.value.length ? selectedProposers.value.includes(movie.proposer) : true;
      const matchesOriginalLanguage = selectedOriginalLanguages.value.length ? selectedOriginalLanguages.value.includes(movie.original_language) : true;
      return matchesQuery && matchesProposer && matchesOriginalLanguage;
    });
    // Order
    const params = JSON.parse(orderSelection.value);
    return filteredMovies.sort((a, b) => {
      if (params.val === 'title') {
        return a.title.localeCompare(b.title) * params.dir;
      } else if (params.val === 'release_date') {
        return (Date.parse(a.release_date) - Date.parse(b.release_date)) * params.dir;
      } else if (params.val === 'viewing_date') {
        // We want all movies with no viewing date to be at the end of the list, either asc. or desc.
        let nilDateVal = params.dir > 0 ? 100000000000000 : 1;
        let aDate = a.viewing_date ? Date.parse(a.viewing_date) : new Date(nilDateVal)
        let bDate = b.viewing_date ? Date.parse(b.viewing_date) : new Date(nilDateVal)
        return (aDate - bDate) * params.dir;
      }
    });
  });
</script>