<template>
  <Pie 
    :id="props.id"
    :options="{ ...chartOptions, ...props.chartOptions }"
    :data="chartData"
    :style="chartStyle"
  />
</template>

<script setup>
  import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
  import { Pie } from 'vue-chartjs';

  import { computed } from 'vue';

  ChartJS.register(ArcElement, Tooltip, Legend);

  const props = defineProps(['id', 'chartData', 'chartOptions']);

  const chartOptions = {
    responsive: true,
  };
  const chartStyle = computed(() => {
    return {
      height: `${window.screen.width / 3}px`,
      width: `${window.screen.width / 3}px`,
    };
  });
</script>