import { computed, onMounted } from 'vue';
import { useTmdbStore } from '../stores/tmdb_store';

export function useMovieCharts(graphId, metadata, extraOptions) {
  const store = useTmdbStore();

  // Load Data or Access Cached Data
  onMounted(() => {
    if (!graphConfig.value) store.fetchGraphConfig(graphId);
  });
  const graphConfig = computed(() => {
    return store.graphConfigs[graphId];
  });
  
  // Tooltips
  const tooltipsCallback = (tooltipItems) => {
    const dataIndex = tooltipItems.dataIndex;
    const datasetIndex = tooltipItems.datasetIndex;
    return graphConfig.value.tooltips[datasetIndex][dataIndex];
  };
  
  // Return values
  const chartData = computed(() => {
    if (graphConfig.value) {
      if (metadata?.dataLimit) {
        const limitedData = graphConfig.value.data;
        limitedData.labels = limitedData.labels.slice(0, metadata.dataLimit);
        return limitedData
      } else {
        return graphConfig.value.data
      }
    }
  });
  const chartOptions = computed(() => {
    if (graphConfig.value) {
      const options = graphConfig.value.options;
      if (graphConfig.value.tooltips) {
        options.plugins = { tooltip: { callbacks: { beforeLabel: tooltipsCallback } } };
      }
      return options;
    }
  });
  return { chartData, chartOptions };
}