<template>
  <div class="grid-container">
    <ResumeMusicPostIt
      :top="4"
      :right="80"
      text="A collection of a capella moments from film and TV that I thought could use some help"
    />
    <div class="grid-row">
      <ResumeMusicVimeoEmbed :id="365272563"/>
    </div>
    <div class="grid-row">
      <ResumeMusicVimeoEmbed :id="457165730"/>
      <ResumeMusicVimeoEmbed :id="457167350"/>
    </div>
  </div>
</template>
