<template>
  <div class="t-24 fw-7"> Remove Vote </div>
  <div class="t-20 fw-5 m-tx6"> {{ poll.title }} </div>
  <div class="f-ac m-tx4">
    <div class="t-18 w-x20 m-rx2">Name:</div>
    <select name="vote" class="input-select w-x70" v-model="voteId">
      <option v-for="vote in poll.votes" :value="vote.id"> {{ vote.voter_name }} </option>
    </select>
  </div>
  <div class="f-ac m-tx4">
    <div class="t-18 w-x20 m-rx2">PIN:</div>
    <input type="text" class="input-text" v-model="pin">
  </div>
  <button class="button m-tx6" @click="submit"> Delete </button>
</template>

<script setup>
  import { ref, computed } from 'vue';
  import { useModalStore } from '../../../stores/modal_store.js';
  import axios from 'axios';
  const modalStore = useModalStore();

  const poll = computed(() => modalStore.data.poll);

  const voteId = ref(null);
  const pin = ref(null);

  const submit = () => {
    const pollId = poll.value.id;
    axios.delete(`/polls/${poll.value.id}/poll_votes/${voteId.value}?pin=${pin.value}`)
      .then(() => {
        window.location.href = '/polls';
      })
      .catch((error) => {
        console.log(error);
      })
  }
</script>