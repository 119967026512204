<template>
  <div class="w-100p bg-cyan-light br-8 p-bx2">
    <div class="f-ac f-jc p-tx4">
      <select v-model="selectedChartValue" class="input-select w-x100 ta-c" id="chart-selector">
        <option value="pp">Player Points</option>
        <option value="pvp">Player Points vs Pick Order</option>
      </select>
    </div>
    <FantasyChartsPlayersPoints
      v-if="selectedChartValue === 'pp'"
      :data="allStats"
    />
    <FantasyChartsPointsVsPick
      v-if="selectedChartValue === 'pvp'"
      :data="allStats"
    />
  </div>
</template>

<script setup>
  import { ref, computed } from 'vue';
  import { useFantasyStore } from "../../stores/fantasy_store.js";
  const fantasyStore = useFantasyStore();
  const props = defineProps(['draft']);
  
  const selectedChartValue = ref('pp');

  const seasonsSinceDraft = computed(() => fantasyStore.seasonsSinceDraft(props.draft));
  const allStats = computed(() => {
    return props.draft.picks.map((pick) => {
      const player = fantasyStore.players[pick.player_id];
      const user = fantasyStore.users.find(u => u.user_id === pick.picked_by);
      return {
        pick,
        player,
        user,
        ...fantasyStore.statsForPlayerForSeasons(player, seasonsSinceDraft.value),
      };
    });
  });
</script>
