<template>
  <div class="modal-overlay" @click="closeModal"></div>
  <div class="modal">
    <component v-if="store.componentName" :is="store.componentName" />
  </div>
</template>

<script setup>
  import { useModalStore } from '../stores/modal_store.js';
  const store = useModalStore();

  const closeModal = () => {
    if (!store.closingForbidden) {
      store.closeModal();
    }
  };
</script>