<template>
  <div class="f-v w-x80 p-x4 bg-cyan-light br-8">
    <div class="f-sb bg-white p-x2 br-4 cyan">
      <div class="f-ac t-16 fw-5">{{ formattedDate }}</div>
    </div>
    <div class="f-sb m-tx3">
      <div v-for="roster in rosters" class="f-v">
        <div class="f-ac m-bx3">
          <FantasySleeperAvatar :user="userByRoster[roster.roster_id]" size="small" class="m-r4" />
          <div class="t-16 fw-5">{{ userByRoster[roster.roster_id].display_name }}</div>
        </div>
        <div v-for="add in addsByRoster[roster.roster_id]" class="m-b4">
          <div class="t-12">{{ add.full_name }}</div>
          <div v-if="statsFetched" class="m-lx2">
            <div class="t-12">Since the trade has:</div>
            <div class="t-12">Played {{ statsSummaryForPlayer(add.player_id).totalGames }} Games</div>
            <div class="t-12">Scored {{ statsSummaryForPlayer(add.player_id).totalPoints }} Points</div>
          </div>
        </div>
        <div v-for="pick in picksByRoster[roster.roster_id]" class="m-b4">
          <div class="t-12">{{ pick.season }}, Round {{ pick.round }}</div>
        </div>
      </div>
    </div>
    <div class="f-je m-tx2">
      <button v-if="!statsFetched" :class="`button bg-cyan ${fetchingStats ? 'disabled' : ''}`" @click="fetchStats">Get Stats</button>
    </div>
  </div>
</template>

<script setup>
  import { ref, computed } from 'vue';

  import { useFantasyStore } from '../../stores/fantasy_store.js';
  const fantasyStore = useFantasyStore();

  const { trade } = defineProps(['trade']);

  // Date
  const date = computed(() => new Date(trade.created));
  const formattedDate = computed(() => {
    return new Intl.DateTimeFormat('en-US', {
      dateStyle: 'long',
      timeZone: 'EST',
    }).format(date.value);
  });

  // Involved Parties
  const rosters = computed(() => {
    return trade.roster_ids.map((id) => {
      return fantasyStore.rosters.find(roster => roster.roster_id === id);
    });
  });
  const userByRoster = computed(() => {
    const users = {};
    rosters.value.forEach((roster) => {
      users[roster.roster_id] = fantasyStore.users.find(user => user.user_id === roster.owner_id);
    });
    return users;
  });

  // Assets
  const players = computed(() => fantasyStore.players);
  const addsByRoster = computed(() => {
    const adds = {};
    if (trade.adds) {
      rosters.value.forEach((roster) => {
        const playerIdsAdded = Object.keys(trade.adds).filter((playerId) => {
          return trade.adds[playerId] === roster.roster_id;
        });
        adds[roster.roster_id] = playerIdsAdded.map(id => players.value[id]);
      });
    }
    return adds;
  });
  const picksByRoster = computed(() => {
    const picks = {};
    if (trade.draft_picks) {
      trade.draft_picks.forEach((pick) => {
        if (picks[pick.owner_id]) {
          picks[pick.owner_id].push(pick);
        } else {
          picks[pick.owner_id] = [pick]
        }
      });
    }
    return picks;
  });

  // Stats
  const fetchingStats = ref(false);
  const statsFetched = ref(false);
  const fetchStats = () => {
    fetchingStats.value = true;
    let numPlayerStatsFetched = 0;
    Object.keys(addsByRoster.value).forEach((rosterId) => {
      addsByRoster.value[rosterId].forEach((player) => {
        fantasyStore.fetchPlayerMetrics({ playerId: player.player_id, year: 2024, metricsType: 'stats' }).then(() => {
          numPlayerStatsFetched += 1;
          if (numPlayerStatsFetched === Object.keys(addsByRoster.value).length) {
            fetchingStats.value = false;
            statsFetched.value = true;
          }
        });
      })
    });
  };

  const statsSummaryForPlayer = (playerId) => {
    const weeksSinceTrade = [];
    if (statsFetched.value) {
      const metrics = players.value[playerId].metrics;
      Object.keys(metrics).forEach((year) => {
        const weeks = metrics[year];
        Object.keys(weeks).forEach((week) => {
          if (weeks[week] && weeks[week].stats) {
            const date = new Date(weeks[week].stats.date);
            const timecode = date.getTime();
            if (timecode > trade.created) weeksSinceTrade.push(weeks[week].stats);
          }
        });
      });
      let totalGames = 0;
      let totalPoints = 0;
      weeksSinceTrade.forEach((week) => {
        if (week.stats.pts_half_ppr) {
          totalGames += 1;
          totalPoints += week.stats.pts_half_ppr;
        }
      });
      return { totalGames, totalPoints: Math.round(totalPoints * 100) / 100 };
    }
  };
</script>