<template>
  <MoviesLog v-if="tmdbStore.selectedMovie" />
</template>

<script setup>
  import { onMounted } from 'vue';

  const { movie, toast, prefills } = defineProps(['movie', 'toast', 'prefills']);

  // Selected Movie
  import { useTmdbStore } from '../../stores/tmdb_store.js';
  const tmdbStore = useTmdbStore(); 
  
  // Error toast
  import { useToastStore } from '../../stores/toast_store.js';
  const { setToast } = useToastStore();

  onMounted(() => {
    tmdbStore.selectMovie(movie);
    tmdbStore.setPrefills(prefills);
    if (toast) setToast(toast);
  });
</script>