<template>
  <div class="f-jc m-tx6">
    <button :class="`button m-rx1 ${selectedType === 'mtpp' ? 'selected' : ''}`" @click="() => selectedType = 'mtpp'">Total: Top 10</button>
    <button :class="`button m-rx1 ${selectedType === 'ltpp' ? 'selected' : ''}`" @click="() => selectedType = 'ltpp'">Total: Bottom 10</button>
    <button :class="`button m-rx1 ${selectedType === 'mapp' ? 'selected' : ''}`" @click="() => selectedType = 'mapp'">Per Game: Top 10</button>
    <button :class="`button ${selectedType === 'lapp' ? 'selected' : ''}`" @click="() => selectedType = 'lapp'">Per Game: Bottom 10</button>
  </div>
  <Bar
    :id="selectedType"
    :data="data"
    :options="options"
    class="m-x8 p-tx8 p-bx8"
  />
</template>

<script setup>
  import { ref, computed } from 'vue';
  import { Bar } from 'vue-chartjs'
  import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'
  ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)
  const props = defineProps(['data']);

  const typeSpecifics = {
    'mtpp': { key: 'totalPoints', sortMultiplier: 1, yAxisLabel: 'Total Points Since Draft' },
    'ltpp': { key: 'totalPoints', sortMultiplier: -1, yAxisLabel: 'Total Points Since Draft' },
    'mapp': { key: 'avgPoints', sortMultiplier: 1, yAxisLabel: 'Points Per Game Since Draft' },
    'lapp': { key: 'avgPoints', sortMultiplier: -1, yAxisLabel: 'Points Per Game Since Draft' },
  };
  const selectedType = ref('mtpp');

  const data = computed(() => {
    const key = typeSpecifics[selectedType.value].key;
    const sortMultiplier = typeSpecifics[selectedType.value].sortMultiplier;
    const sortedData = props.data.sort((a, b) => (b[key] - a[key]) * sortMultiplier);
    const relevantData = sortedData.slice(0, 10);
    return {
      labels: relevantData.map(datum => datum.player.full_name),
      datasets: [
        {
          label: 'Points',
          data: relevantData.map(datum => datum[key] || 0),
          backgroundColor: '#0BD2D3',
        }
      ],
    };
  });

  const options = computed(() => {
    return {
      responsive: true,
      maintainAspectRatio: true,
      scales: {
        y: {
          display: true,
          title: {
            display: true,
            text: typeSpecifics[selectedType.value].yAxisLabel,
            font: { size: 14, weight: 'bold' },
          }
        },
      },
    }
  });
</script>
