<template>
  <div class="f-jc f-ac">
    <div class="f-jc m-tx2">
      <select v-model="currentSeason" class="input-select w-x36 ta-c" id="season-selector">
        <option value="every">Every Season</option>
        <option v-for="season in allSeasons" :value="season">{{ season }} Season</option>
      </select>
    </div>
    <div class="f-jc m-tx2">
      <select v-model="chartType" class="input-select w-x60 ta-c" id="chart-selector">
        <option value="points">Points</option>
        <option value="trends">Trends</option>
        <option value="stdDev">Std. Dev. [{{ fptsStandardDeviation }}]</option>
      </select>
    </div>
  </div>
  <Line
    id="points-vs-proj"
    :data="data"
    :options="options"
    class="m-x2"
  />
</template>

<script setup>
  import { ref, computed } from 'vue';

  import { useFantasyStore } from '../../../stores/fantasy_store';
  const fantasyStore = useFantasyStore();

  import { useChartMethods } from '../../../composables/chart_methods.js'; 
  const { chartColors, getTrendlinePoints, standardDeviation } = useChartMethods();

  import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js'
  import { Line } from 'vue-chartjs';
  ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

  const { player, analysis } = defineProps(['player', 'analysis']);

  const allSeasons = computed(() => {
    return fantasyStore.leagues.map(l => l.season).sort();
  });
  const currentSeason = ref('2024');
  const selectedRange = computed(() => {
    const seasons = currentSeason.value === 'every' ? allSeasons.value : [currentSeason.value];
    return seasons.flatMap((season) => {
      const seasonData = analysis[season];
      const sortedWeeks = Object.keys(seasonData).sort((a, b) => {
        return parseInt(a) - parseInt(b);
      });
      return sortedWeeks.map((week) => {
        return { year: season, week };
      });
    });
  });

  // Labels
  const labels = computed(() => {
    return selectedRange.value.map(way => `Week ${way.week}`);
  });

  // Fpts and Proj
  const chartType = ref('points');
  const fptsData = computed(() => selectedRange.value.map((way) => parseInt(analysis[way.year][way.week].fpts.value)));
  const fptsTrend = computed(() => getTrendlinePoints(fptsData.value));
  const projData = computed(() => selectedRange.value.map((way) => parseInt(analysis[way.year][way.week].proj.value)));
  const projTrend = computed(() => getTrendlinePoints(projData.value));
  const fptsStandardDeviation = computed(() => standardDeviation(fptsData.value.filter(d => d)).toFixed(2));
  const projStandardDeviation = computed(() => standardDeviation(projData.value.filter(d => d)).toFixed(2));

  // Datasets
  const datasets = computed(() => {
    const fptsDataDataset = {
      label: 'Points',
      data: fptsData.value,
      backgroundColor: chartColors[0],
    };
    const projDataDataset = {
      label: 'Proj',
      data: projData.value,
      backgroundColor: chartColors[1],
    };
    if (chartType.value === 'points') {
      return [fptsDataDataset, projDataDataset];
    } else {
      const fptsTrendDataset = {
        label: 'Points [Trend]',
        data: fptsTrend.value,
        borderColor: chartColors[0],
        backgroundColor: chartColors[0],
        pointRadius: 0,    
      };
      const projTrendDataset = {
        label: 'Proj [Trend]',
        data: projTrend.value,
        borderColor: chartColors[1],
        backgroundColor: chartColors[1],
        pointRadius: 0,    
      };
      if (chartType.value === 'trends') {
        return [fptsDataDataset, fptsTrendDataset, projDataDataset, projTrendDataset];
      } else if (chartType.value === 'stdDev') {
        const fptsTrendDatasets = [-1, 1].map((dir) => {
          const adjustedData = fptsTrendDataset.data.map(datum => datum ? datum + (dir * fptsStandardDeviation.value) : null);
          return {
            label: `Points [${dir > 0 ? 'Trend: Upper Bounds' : 'Trend: Lower Bounds'}]`,
            data: adjustedData,
            borderColor: chartColors[0],
            backgroundColor: chartColors[0],
            pointRadius: 0,        
          };
        });
        const projTrendDatasets = [-1, 1].map((dir) => {
          const adjustedData = projTrendDataset.data.map(datum => datum ? datum + (dir * projStandardDeviation.value) : null);
          return {
            label: `Proj. [${dir > 0 ? 'Trend: Upper Bounds' : 'Trend: Lower Bounds'}]`,
            data: adjustedData,
            borderColor: chartColors[1],
            backgroundColor: chartColors[1],
            pointRadius: 0,        
          };
        });
        return [fptsDataDataset, ...fptsTrendDatasets, projDataDataset, ...projTrendDatasets];
      }
    }
  });
  const data = computed(() => {
    return {
      labels: labels.value,
      datasets: datasets.value,
    };
  });

  // Options
  const options = computed(() => {
    return {
      responsive: true,
      maintainAspectRatio: true,
      spanGaps: true,
      scales: {
        y: {
          beginAtZero: true,
        },
      },
    }
  });
</script>