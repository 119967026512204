<template>
  <iframe
    class="m-x3"
    :width="width"
    height="450"
    scrolling="no"
    frameborder="no"
    allow="autoplay"
    :src="src"
  ></iframe>
</template>

<script setup>
  import { computed } from 'vue';
  import { useScreens } from '../../../composables/screens.js';

  const { type, id } = defineProps(['type', 'id']);
  const { isMobile } = useScreens();

  const width = computed(() => {
    if (isMobile.value) return '100%';
    return '40%';
  });

  const src = computed(() => {
    const url = 'https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/';
    let params = "&color=%230bd2d3&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true";
    if (type === 'tracks' || isMobile) params += "&visual=true";
    return url + type + '/' + id + params;
  });

</script>