<template>
  <div class="t-28 fw-7 onyx">Fantasy Football</div>
  <div class="t-12 fw-5 m-tx8">Enter Sleeper League ID:</div>
  <div class="f-ac m-tx2">
    <input class="input-text" placeholder="League ID...." v-model="leagueId">
    <a :class="`button w-x14 h-x5 m-lx1 ${leagueId.trim() ? '' : 'disabled'}`" :href="path">Go</a>
  </div>
</template>

<script setup>
  import { ref, computed } from 'vue';

  const leagueId = ref('');
  const path = computed(() => {
    if (leagueId) return `/apps/fantasy/${leagueId.value}`;
  });
</script>