<template>
  <div class="grid-container">
    <div class="grid-row">
      <ResumeMusicVimeoEmbed :id="219755414">
        <ResumeMusicPostIt text="Password is 'Nomads2017'" />
      </ResumeMusicVimeoEmbed>
      <ResumeMusicVimeoEmbed :id="148148000"/>
    </div>
  </div>
</template>
