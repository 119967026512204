<template>
  <component :class="classes" :is="href ? 'a' : 'div'" :href="href">
    <img 
      v-if="movie.poster_path" 
      :src="fullPath" 
      :alt="movie.title"
      class="br-4 b-1-dark-gray"
    >
    <div v-else :class="`h-100p bg-white br-4 b-1-dark-gray ta-c f-ac ${posterSizes[size] || 'w-92'}`">
      <div class="t-12 gray"> [image not found] </div>
    </div>
  </component>
</template>

<script setup>
  import { computed, toRefs } from 'vue';
  const props = defineProps(['movie', 'size', 'isLink', 'href']);
  const { movie, size, isLink, href } = toRefs(props);

  const posterSizes = {
    "w92": "w-92",
    "w154": "w-154",
    "w185": "w-185",
    "w342": "w-342",
    "w500": "w-500",
    "w780": "w-780",
    "original": "w-100p"
  };

  const classes = computed(() => {
    let classes = 'd-ib m-x2 br-4';
    if (isLink.value || href.value) classes += ' c-p hover-translate';
    return classes
  });

  const fullPath = computed(() => {
    const baseUrl = 'http://image.tmdb.org/t/p/';
    const posterSize = size.value || 'w92';
    return baseUrl + posterSize + movie.value.poster_path;
  });
</script>