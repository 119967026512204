import { ref, onMounted, onUnmounted, computed } from 'vue';

export function useScreens() {
  const screenWidth = ref(window.innerWidth);
  
  const isMobile = computed(() => {
    return screenWidth.value <= 800;
  });

  const onResize = () => {
    screenWidth.value = window.innerWidth;
  };

  onMounted(() => {
    window.addEventListener('resize', onResize);
  });
  onUnmounted(() => {
    window.removeEventListener('resize', onResize);
  });

  return { screenWidth, isMobile };
}