<template>
  <div>
    <iframe 
      style="border-radius:12px" 
      src="https://open.spotify.com/embed/album/5rSM1VJujKNosqF0eVFkDS?utm_source=generator" 
      width="100%" 
      height="352" 
      frameBorder="0" 
      allowfullscreen="" 
      allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" 
      loading="lazy"
    ></iframe>
    <iframe
      class="m-tx3 m-bx3"
      style="border-radius:12px"
      src="https://open.spotify.com/embed/album/1HveBoKVbfDMp27WU7iqZb?utm_source=generator"
      width="100%"
      height="352"
      frameBorder="0"
      allowfullscreen=""
      allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
      loading="lazy"
    ></iframe>
  </div>
</template>
