<template>
  <div class="f-ac">
    <label class="input-toggle">
      <input type="checkbox" v-model="isChecked">
      <span class="input-toggle-slider"></span>
    </label>
    <div class="t m-lx1">{{ props.label }}</div>
  </div>
</template>

<script setup>
  import { computed, defineEmits } from 'vue';

  const emit = defineEmits(['update:modelValue']);
  const props = defineProps({
    label: {
      type: String,
      default: null,
    },
    modelValue: {
      type: Boolean,
      required: true,
    },
  });

  const isChecked = computed({
    get() { return props.modelValue },
    set(value) { emit('update:modelValue', value) },
  });
</script>