import { defineStore } from "pinia";

export const useModalStore = defineStore("ModalStore", {
  state: () => {
    return {
      componentName: null,
      data: {},
      closingForbidden: false,
    };
  },

  actions: {
    updateModal({ componentName, data, closingForbidden }) {
      this.componentName = componentName;
      this.data = data;
      this.closingForbidden = closingForbidden || false;
    },

    closeModal() {
      this.componentName = null;
      this.data = {};
      this.closingForbidden = false;
    },
  },
});