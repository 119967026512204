<template>
  <FantasyTradePreview v-for="trade in trades" :trade="trade" class="m-tx5"/>
</template>

<script setup>
  import { computed } from 'vue';
  import { useFantasyStore } from '../../stores/fantasy_store.js';
  const fantasyStore = useFantasyStore();

  const trades = computed(() => fantasyStore.trades);

  const goBack = () => {
    fantasyStore.selectView(null);
  };
</script>