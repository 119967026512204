<template>
  <div class="t-24 fw-7"> Upload </div>
  <div class="t-16 m-tx6">Bulk "upload" receipts from approved websites and automatically create Bill Splitter tabs.</div>
  <div class="t-16 m-tx6">Select receipt type below.</div>
  <div class="f m-tx6">
    <button class="button" @click="updateModal('caviar')">Caviar</button>
    <button class="button m-lx2" @click="updateModal('seamless')">Seamless</button>
  </div>
</template>

<script setup>
  import { useModalStore } from "../../../stores/modal_store.js";

  const modalStore = useModalStore();

  const updateModal = (type) => {
    if (type === 'caviar') {
      modalStore.updateModal({
        componentName: 'BillSplitterModalsUploadCaviar',
      });
    } else if (type === 'seamless') {
      modalStore.updateModal({
        componentName: 'BillSplitterModalsUploadSeamless',
      })
    }
  }
</script>