<template>
  <MoviesLog v-if="tmdbStore.selectedMovie" />
  <MoviesSearch v-else />
</template>

<script setup>
  import { onMounted } from 'vue';

  const { toast, prefills } = defineProps(['toast', 'prefills']);

  // Selected Movie
  import { useTmdbStore } from '../../stores/tmdb_store.js';
  const tmdbStore = useTmdbStore();
  
  // Error toast
  import { useToastStore } from '../../stores/toast_store.js';
  const { setToast } = useToastStore();
  
  onMounted(() => {
    if (toast) setToast(toast);
    tmdbStore.setPrefills(prefills);
  });
</script>