<template>
  <div class="t-28 fw-7 onyx">{{ league.name || 'Sleeper' }}</div>
  <div class="t-12 fw-5 m-tx3">[{{ fantasyStore.apiCallsInLastMinute }} API Calls Made in Last Minute]</div>
  <BaseBreadcrumbs v-if="selectedView" :crumbs="[{ clickMethod: goBack, label: 'Fantasy Menu' }]" class="m-tx2" />
  <template v-if="leagueInvalid">
    <div class="t-18 fw-6 m-tx6 pink f-ac">
      <span class="material-symbols-outlined m-rx1"> warning </span>
      <span>Sorry, could not find a Sleeper league with ID [{{ leagueId }}]</span>
    </div>
  </template>
  <BaseLoading v-else-if="!fullyLoaded" class="m-tx8 w-80p" />
  <FantasyMenu v-else-if="!selectedView" />
  <FantasyTrades v-else-if="selectedView.trades" />
  <FantasyDrafts v-else-if="selectedView.drafts" />
  <FantasyUser v-else-if="selectedView.user" />
</template>

<script setup>
  import { ref, computed, onMounted } from 'vue';
  import { useFantasyStore } from '../../stores/fantasy_store.js';
  const fantasyStore = useFantasyStore();
  const { leagueId } = defineProps(['leagueId']);

  const league = computed(() => fantasyStore.league);
  const selectedView = computed(() => fantasyStore.selectedView);

  const leagueInvalid = ref(false);
  const usersLoaded = ref(false);
  const fullyLoaded = ref(false);

  onMounted(() => {
    fantasyStore.fetchLeague(leagueId).then((leagueSuccessfullyLoaded) => {
      if (leagueSuccessfullyLoaded) {
        fantasyStore.fetchNflState()
          .then(() => fantasyStore.fetchRosters())
          .then(() => fantasyStore.fetchUsers())
          .then(() => usersLoaded.value = true)
          .then(() => fantasyStore.fetchTransactions())
          .then(() => fantasyStore.fetchPlayers())
          .then(() => fullyLoaded.value = true);
        } else {
        leagueInvalid.value = true;
      }
    });
  });

  const goBack = () => {
    fantasyStore.selectView(null);
  };
</script>