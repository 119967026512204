<template>
  <div class="person">
    <div class="f-sb f-ac m-x2">
      <div class="t-14 white">
        <span>{{ person.name }}</span>
        <span> - </span>
        <span>{{ billSplitterStore.currencyFormat.format(totalForPerson) }}</span>
      </div>
      <span class="material-symbols-outlined t-14 white c-p" @click="removePerson"> close </span>
    </div>
    <div class="item-list" @dragover.prevent @drop="onDrop">
      <div
        v-for="(item, idx) in person.items"
        :key="`item-${idx}`"
        class="item-tag"
      >
        <span>{{ itemDisplay(item) }}</span>
        <span class="material-symbols-outlined t-10 white c-p m-lx1" @click="removeItem(idx)"> close </span>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { computed } from 'vue';
  import { useBillSplitterStore } from '../../stores/bill_splitter_store';

  const { personIdx } = defineProps(['personIdx']);
  const billSplitterStore = useBillSplitterStore();

  const person = computed(() => billSplitterStore.selectedTab.people[personIdx]);
  const totalForPerson = computed(() => billSplitterStore.totalForPerson(personIdx));

  const itemDisplay = (item) => {
    const proportionalPrice = item.price * item.perc;
    return item.name + ': ' + billSplitterStore.currencyFormat.format(proportionalPrice);
  };

  const removePerson = () => {
    billSplitterStore.removePerson(personIdx);
  };
  const addItem = (item) => {
    billSplitterStore.addItemForPerson(personIdx, item);
  };
  const removeItem = (itemIdx) => {
    billSplitterStore.removeItemForPerson(personIdx, itemIdx);
  };

  const onDrop = (e) => {
    const item = JSON.parse(e.dataTransfer.getData('text/plain'));
    addItem(item);
  };
</script>