<template>
  <form class="contact" ref="form" @submit.prevent="sendEmail">
    <ResumeMusicPostIt :class="postItClass" :text="postItText" :destroyable="false"/>
    <div class="header">Send me a message!</div>
    <div class="row">
      <label>Name:</label>
      <input v-model="name" name="name">
    </div>
    <div class="row">
      <label>Email:</label>
      <input v-model="email" type="email" name="email">
    </div>
    <div class="row">
      <label>Message:</label>
      <textarea v-model="message" name="message" />
    </div>
    <div class="row">
      <input type="submit" class="button" :disabled="disabled" value="Send">
    </div>
  </form>
</template>

<script setup>
  import { ref, computed } from 'vue';
  import emailjs from '@emailjs/browser';

  const name = ref(null);
  const email = ref(null);
  const message = ref(null);
  const sending = ref(false);
  const postItText = ref(null);
  const postItClass = ref('toast-post-it');
  const form = ref(null);

  const disabled = computed(() => {
    if (sending.value) return true;
    return !name.value || !email.value || !message.value;
  });

  const sendEmail = () => {
    sending.value = true;
    emailjs.send("service_thm99aq","template_i0egbgp",{
      name: name.value,
      email: email.value,
      message: message.value,
    }, { publicKey: 'JxDhn_2D5kkXHlRwM' })
    .then(() => {
      sending.value = false;
      name.value = null;
      email.value = null;
      message.value = null;
      triggerPostIt('Email sent!');
    })
    .catch((error) => {
      sending.value = false;
      triggerPostIt('Something went wrong... Try again?');
    });
  };

  const triggerPostIt = (text) => {
    postItText.value = text;
    postItClass.value = 'toast-post-it visible';
    setTimeout(() => {
      postItText.value = null;
      postItClass.value = 'toast-post-it';
    }, 2000);
  };
</script>