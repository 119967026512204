<template>
  <div class="f-v w-x72">
    <div :class="`${isMobile ? 'h-x70' : 'h-x100'} w-x72 bg-gray br-4`">
      <div class="f-v f-sb h-100p">
        <div>
          <div class="pos-rel m-x4">
            <span class="material-symbols-outlined pos-abs left-4 top-4"> search </span>
            <input class="input-text w-x54 p-lx8" v-model="searchQuery" placeholder="Enter address..." @keyup.enter="performSearch">
          </div>
          <div v-for="(location, idx) in midpointStore.locations" class="f m-lx4 m-bx2">
            <span class="material-symbols-outlined t-14 onyx c-p m-rx1" @click="removeLocation(idx)"> close </span>
            <div class="t-14">{{ location.title }}</div>
          </div>
        </div>
        <div
          v-if="!isMobile"
          :class="`button m-x4 ${midpointStore.locations.length > 0 ? '' : 'disabled'}`"
          @click="recenterMap"
        >Recenter</div>
      </div>
    </div>
    <div class="h-x24 w-x72 bg-gray br-4 m-tx4">
      <div class="m-x4" v-if="midpointStore.geographicalMidpoint">
        <div class="t-14 black m-bx2">Geographical Midpoint:</div>
        <a class="t-14 black fw-7" :href="geographicalMidpointUrl" target="_blank">{{ geographicalMidpointTitle }}</a>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { ref, computed } from 'vue';
  import { useMidpointStore } from '../../stores/midpoint_store.js';
  import { useScreens } from '../../composables/screens.js';

  const midpointStore = useMidpointStore();
  const { isMobile } = useScreens();

  const searchQuery = ref('');
  const performSearch = () => {
    const query = searchQuery.value.trim();
    if (query) {
      midpointStore.performSearch(query);
      searchQuery.value = '';
    }
  };

  const removeLocation = (idx) => {
    midpointStore.removeLocation(idx);
  };

  const recenterMap = () => {
    midpointStore.recenterMapAroundMarkers();
  };

  const geographicalMidpointTitle = computed(() => {
    const title = midpointStore.geographicalMidpoint.title;
    if (title) {
      return title;
    } else {
      const position = midpointStore.geographicalMidpoint.position;
      return `[${position.lat}, ${position.lng}]`;
    }
  });
  const geographicalMidpointUrl = computed(() => {
    const title = midpointStore.geographicalMidpoint.title;
    if (title) {
      return `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(title)}`;
    } else {
      const position = midpointStore.geographicalMidpoint.marker.position;
      return `https://www.google.com/maps/search/?api=1&query=${position.lat},${position.lng}`;
    }
  })
</script>