<template>
  <MoviesChartsPieTemplate
    v-if="chartData"
    id="bechdel-grades-chart"
    :chart-data="chartData"
    :chart-options="chartOptions"
  />
</template>

<script setup>
  import { useMovieCharts } from '../../../composables/movie_charts.js';
  
  const { chartData, chartOptions } = useMovieCharts('bechdel');
</script>