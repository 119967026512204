<template>
  <div class="t-24 fw-7 m-bx4"> Upload - Seamless </div>
  <div class="t-16 h-x6 f-ac">
    <span>1. Click on the "</span>
    <span class="material-symbols-outlined t-18">history</span>
    <span>Past Orders" section in the the profile dropdown</span>
  </div>
  <div class="t-16 h-x6 f-ac">2. Click "View order details" on all relevant orders</div>
  <div class="t-16 h-x6 f-ac">3. Press Ctrl+A then Ctrl+C to copy contents of receipt</div>
  <div class="t-16 h-x6 f-ac">4. Ctrl+V in text input below to paste and parse receipt</div>
  <div class="t-16 f-ac m-tx2" v-for="receipt in receipts">
    <template v-if="receipt">
      <span class="material-symbols-outlined seafoam m-rx1">new_releases</span>
      <span>{{ receipt.title }}</span>
    </template>
    <template v-else>
      <span class="material-symbols-outlined pink m-rx1">error</span>
      <span>Upload Unsuccesful</span>
    </template>
  </div>
  <input
    class="input-text m-tx4"
    placeholder="Paste 'receipt' here..."
    @keypress.prevent
    @paste="handlePaste"
  >
  <div class="f-je w-100p m-tx4">
    <button :class="`button ${buttonClasses}`" @click="addReceipts">Add Receipts</button>
  </div>
</template>

<script setup>
  import { ref, computed } from 'vue';
  import { useBillSplitterStore } from '../../../stores/bill_splitter_store';
  import { useModalStore } from '../../../stores/modal_store';

  const billSplitterStore = useBillSplitterStore();
  const modalStore = useModalStore();

  const receipts = ref([]);

  const handlePaste = (e) => {
    e.stopPropagation();
    e.preventDefault();

    const clipboardData = e.clipboardData || window.clipboardData;
    const pastedData = clipboardData.getData('Text');

    const parsedReceipt = parseReceipt(pastedData);
    receipts.value.push(parsedReceipt);
  };

  const parseReceipt = (receipt) => {
    const receiptParts = receipt.split('\n');

    const restaurantIdx = receiptParts.findIndex((detail) => detail === 'Ordered from') + 1;
    const restaurant = receiptParts[restaurantIdx];
    const dateIdx = receiptParts.findIndex((detail) => detail === 'Your order') + 1;
    const date = receiptParts[dateIdx].split(',')[0];

    const parsedReceipt = { title: restaurant + ': ' + date };

    const detailsStartIdx = receiptParts.findIndex((detail) => detail.startsWith('Order #')) + 1;
    const detailsEndIdx = receiptParts.findIndex((detail) => detail === 'Items subtotal');
    const details = receiptParts.slice(detailsStartIdx, detailsEndIdx);
    parsedReceipt.items = parseItems(details);
    if (!parsedReceipt.items) return null;

    const totalIdx = receiptParts.findIndex((detail) => detail === 'Total') + 1;
    parsedReceipt.totalSpent = parseFloat(receiptParts[totalIdx].split('$')[1]);

    return parsedReceipt;
  };

  const parseItems = (details) => {
    const chunks = [];
    let chunk = [];
    for(let i = 0; i < details.length; i++) {
      chunk.push(details[i]);
      if (details[i].startsWith('$')) {
        chunks.push(chunk);
        chunk = [];
      }
    };

    return chunks.map((chunk) => {
      const amountIdx = chunk.findIndex((detail) => parseInt(detail) > 0);
      const amount = chunk[amountIdx];
      let name = chunk[amountIdx + 1];
      if (parseInt(amount) > 1) name += ` (${amount})`;
      const price = parseInt(chunk[chunk.length - 1].split('$')[1]);
      return { name, price };
    });
  };

  // Submission
  const validReceipts = computed(() => receipts.value.filter(r => r));
  const buttonClasses = computed(() => validReceipts.value.length > 0 ? '' : 'disabled');
  const addReceipts = () => {
    validReceipts.value.forEach((receipt) => {
      billSplitterStore.addTab(receipt);
    });
    billSplitterStore.removeEmptyTabs();
    modalStore.closeModal();
  };
</script>