<template>
  <div class="text-input-autocomplete-container">
    <input
      v-if="isMulti"
      v-for="selection in multiSelections"
      type="hidden"
      :name="name"
      :value="selection"
    >
    <textarea :class="`input-text ${inputClass}`"
      :name="isMulti ? null : name"
      v-model="query"
      ref="input"
      @keydown="onKeyDown" 
      @keyup="onKeyUp"
      @focus="onFocus"
      @blur="onBlur"
    />
    <div v-if="isMulti" class="input-multiselect-container" ref="multiselectContainer">
      <div v-for="selection in multiSelections" class="input-multiselect-selection" @click="onMultiSelectClick(selection)">{{ selection }}</div>
    </div>
    <div :class="`input-autocomplete ${autocompleteClass} ${showAutocomplete ? '' : 'hidden'}`">
      <div 
        v-for="option in filteredOptions"
        @click.prevent="onClick(option)"
        :class="`input-autocomplete-option ${option === selectedOption ? 'selected' : ''}`"
      >{{ option }}</div>
    </div>
  </div>
</template>

<script setup>
  import { ref, computed, watch, onMounted } from 'vue';
  const { modelValue, options, isMulti, name } = defineProps(['modelValue', 'options', 'isMulti', 'name']);
  const emit = defineEmits(['update:modelValue']);
  
  const query = ref('');
  watch(query, () => {
    if (!isMulti) emit('update:modelValue', query.value);
  });

  // Dynamic Styles
  const inputClass = ref('');
  const autocompleteClass = ref('top-36');

  // Event Handlers
  const onKeyDown = (e) => {
    if (e.keyCode === 38 || e.keyCode === 40 || e.keyCode === 13) e.preventDefault();
    if (e.keyCode === 9) confirmSelection();
  }
  const onKeyUp = (e) => {
    if (e.keyCode === 38 || e.keyCode === 40) {
      moveSelection({ 38: -1, 40: 1 }[e.keyCode]);
    } else if (e.keyCode === 13) {
      confirmSelection();
    } else {
      autocomplete();
    }
  };
  const onFocus = () => {
    inputInFocus.value = true;
    autocomplete();
  };
  const onBlur = () => {
    blurTimeout.value = setTimeout(() => {
      inputInFocus.value = false;
    }, 150);
  };
  const onClick = (option) => {
    if (blurTimeout.value) {
      clearTimeout(blurTimeout.value);
      blurTimeout.value = null;
    }
    focusInput();

    selectedOption.value = option;
    confirmSelection();
  };
  
  // Input Focus Management
  const input = ref(null);
  const inputInFocus = ref(false);
  const blurTimeout = ref(null);
  const focusInput = () => {
    input.value.focus();
    inputInFocus.value = true;
  };
  const blurInput = () => {
    input.value.blur();
    inputInFocus.value = false;
  };
  const showAutocomplete = computed(() => {
    return filteredOptions.value.length && inputInFocus.value;
  })

  // Actions
  const moveSelection = (direction) => {
    const idx = filteredOptions.value.indexOf(selectedOption.value);
    const newSelectedOption = filteredOptions.value[idx + direction];
    if (newSelectedOption) selectedOption.value = newSelectedOption;
  };
  const confirmSelection = () => {
    if (isMulti) {
      const selection = selectedOption.value || query.value.trim();
      if (selection) addMultiSelection(selection);
      query.value = '';
      selectedOption.value = filteredOptions.value[0];
    } else {
      if (selectedOption.value) query.value = selectedOption.value;
      blurInput();
      selectedOption.value = null;
    }
  };
  const autocomplete = () => {
    if (!filteredOptions.value.includes(selectedOption.value)) {
      selectedOption.value = filteredOptions.value[0];
    }
  };
  const filteredOptions = computed(() => {
    const cleanQuery = query.value.toLowerCase().trim();
    if (cleanQuery) {
      const result = options.filter(o => o.toLowerCase().includes(cleanQuery));
      if (isMulti) {
        return result.filter(o => !multiSelections.value.includes(o));
      } else {
        return result;
      }
    } else {
      return options.filter(o => !multiSelections.value.includes(o));;
    }
  });
  const selectedOption = ref(null);

  // Multiselect
  const multiSelections = ref([]);
  const addMultiSelection = (selection) => {
    const newSelections = [...multiSelections.value];
    newSelections.push(selection);
    multiSelections.value = newSelections;
  };
  const multiselectContainer = ref(null);
  watch(multiSelections, () => {
    if (isMulti) {
      emit('update:modelValue', multiSelections.value);
      setTimeout(() => {
        const containerHeight = multiselectContainer.value.offsetHeight;
        inputClass.value = `h-${containerHeight + 20}`;
        autocompleteClass.value = `top-${containerHeight + 36}`;
      }, 50);
    }
  });
  const onMultiSelectClick = (selection) => {
    const newSelections = [...multiSelections.value];
    const index = newSelections.indexOf(selection);
    newSelections.splice(index, 1);
    multiSelections.value = newSelections;
  };

  // Establish values from v-model
  onMounted(() => {
    if (!modelValue) return;
  
    if (isMulti) {
      multiSelections.value = modelValue;
    } else {
      query.value = modelValue;
    }
  });
</script>