<template>
  <div class="about">
    <ResumeMusicSelfPortrait v-if="!isMobile"/>
    <div class="bio-and-contact">
      <div class="bio">
        <span>My name is Will and I am a New York City based composer and producer. </span>
        <span>I have written music for ads, video games, short films, and theater pieces. </span>
        <span>I've worked with music houses </span>
        <a class="link" href="http://www.gimmebutter.com/" target="_blank">Butter</a>
        <span>, </span>
        <a class="link" href="http://www.jinglepunks.com/" target="_blank">Jingle Punks</a>
        <span>, and </span>
        <a class="link" href="http://www.mophonics.com/" target="_blank">Mophonics</a>
        <span>.</span>
      </div>
      <ResumeMusicContactForm />
    </div>
  </div>
</template>

<script setup>
  import { useScreens } from '../../../composables/screens';

  const { isMobile } = useScreens();
</script>