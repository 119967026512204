// To see this message, add the following to the `<head>` section in your
// views/layouts/application.html.erb
//
//    <%= vite_client_tag %>
//    <%= vite_javascript_tag 'application' %>
console.log('Vite ⚡️ Rails')

// If using a TypeScript entrypoint file:
//     <%= vite_typescript_tag 'application' %>
//
// If you want to use .jsx or .tsx, add the extension:
//     <%= vite_javascript_tag 'application.jsx' %>

console.log('Visit the guide for more information: ', 'https://vite-ruby.netlify.app/guide/rails')

// Example: Load Rails libraries in Vite.
//
// import * as Turbo from '@hotwired/turbo'
// Turbo.start()
//
// import ActiveStorage from '@rails/activestorage'
// ActiveStorage.start()
//
// // Import all channels.
// const channels = import.meta.globEager('./**/*_channel.js')

// Example: Import a stylesheet in app/frontend/index.css
// import '~/index.css'

// Create Vue App
import { createApp } from "vue";
import { createPinia } from "pinia";
import router from "../router/index.js";
import App from "../components/App.vue";

const props = JSON.parse(document.getElementById('app').getAttribute('data'));

// Instantiate App and Store
const app = createApp(App, { props });
const pinia = createPinia();

// Register Components
const components = import.meta.glob('../components/**/*.vue', { eager: true });
Object.keys(components).forEach((key) => {
  const component = components[key];
  const nameSections = key.split('components/')[1].split('.vue')[0].split('/').map((section) => {
    const nameSubsections = section.split('-');
    return nameSubsections.map((subsection) => {
      return subsection[0].toUpperCase() + subsection.slice(1, subsection.length);
    }).join('');
  });
  if (nameSections.length === 1) nameSections.unshift('Base');
  const componentName = nameSections.join('')
  app.component(componentName, component.default);
});
// Register Router and Store
app.use(router);
app.use(pinia);
// Mount App
app.mount("#app");
