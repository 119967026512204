<template>
  <div :class="`w-x60 br-16 m-rx2 m-bx2 p-x3 ${backgroundClass}`">
    <div class="f-sb f-ac">
      <div class="t-16 fw-5">{{ player.full_name }}</div>
      <span
        v-if="fullyLoaded"
        class="material-symbols-outlined c-p"
        @click="openPlayerModal"
      > open_in_new </span>
      <span
        v-else
        :class="`material-symbols-outlined c-p ${loading ? 'loading' : ''}`"
        @click="fetchPlayerMetrics"
      > sync </span>
    </div>
  </div>
</template>

<script setup>
  import { ref, computed } from 'vue';
  import { useModalStore } from "../../stores/modal_store.js";
  import { useFantasyStore } from "../../stores/fantasy_store.js";
  const modalStore = useModalStore();
  const fantasyStore = useFantasyStore();

  const { player } = defineProps(['player']);

  const backgroundClass = computed(() => {
    if (player.position === 'QB') return 'bg-seafoam-light';
    if (player.position === 'RB') return 'bg-cyan-light';
    if (player.position === 'WR') return 'bg-pink-light';
    if (player.position === 'TE') return 'bg-gray-light';
    return 'bg-onyx-light';
  });

  const fullyLoaded = ref(false);
  const loading = ref(false);
  const fetchPlayerMetrics = () => {
    loading.value = true;
    fantasyStore.fetchPlayerMetrics({ playerId: player.player_id, metricsType: 'stats' })
      .then(() => fantasyStore.fetchPlayerMetrics({ playerId: player.player_id, metricsType: 'projections' }))
      .then(() => {
        loading.value = false;
        fullyLoaded.value = true;
      });
  };

  const openPlayerModal = () => {
    modalStore.updateModal({
      componentName: 'FantasyModalsPlayer',
      data: { player },
    });
  };
</script>

<style scoped lang="css">
  .loading {
    pointer-events: none;
    opacity: 0.5;
    animation: loading 2s ease infinite;
  }

  @keyframes loading {
    0% { transform: rotate(0deg); }
    50% { transform: rotate(180deg); }
    100% { transform: rotate(360deg); }
  }
</style>