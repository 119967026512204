<template>
  <div class="t-28 fw-7 onyx">Midpoint</div>
  <div :class="`${isMobile ? 'f-v' : 'f'} m-tx8`">
    <MidpointControls :class="isMobile ? 'm-bx4' : 'm-rx4'" />
    <!-- <MidpointMap :map-id="mapId" /> -->
  </div>
</template>

<script setup>
  import { onMounted } from 'vue';
  import { useScreens } from '../../composables/screens.js';
  import { useModalStore } from '../../stores/modal_store';
  const modalStore = useModalStore();

  const { mapId } = defineProps(['mapId']);
  const { isMobile } = useScreens();

  onMounted(() => {
    modalStore.updateModal({
      componentName: 'ModalsUnderConstruction',
      closingForbidden: true,
    });
  });
</script>