<template>
  <div class="t-28 fw-7 onyx">Movie Club Graphs</div>
  <div class="f m-tx4" v-if="!isMobile">
    <button :class="buttonClasses('popularity')" @click="selectGraph('popularity')"> Movie Popularity </button>
    <button :class="buttonClasses('rating')" @click="selectGraph('rating')"> Movie Rating </button>
    <button :class="buttonClasses('actors')" @click="selectGraph('actors')"> Actor Appearances </button>
    <button :class="buttonClasses('bechdel')" @click="selectGraph('bechdel')"> Bechdel Grades </button>
    <button :class="buttonClasses('gender')" @click="selectGraph('gender')"> Gender Analysis </button>
    <button :class="buttonClasses('genre')" @click="selectGraph('genre')"> Genre Analysis </button>
  </div>
  <div class="f-v m-tx8" v-if="!isMobile">
    <MoviesChartsMoviePopularity v-if="selectedGraph === 'popularity'"/>
    <MoviesChartsMovieRating v-if="selectedGraph === 'rating'"/>
    <MoviesChartsActorAppearances v-if="selectedGraph === 'actors'"/>
    <MoviesChartsBechdelGrades v-if="selectedGraph === 'bechdel'"/>
    <MoviesChartsGenderAnalysis v-if="selectedGraph === 'gender'"/>
    <MoviesChartsGenreAnalysis v-if="selectedGraph === 'genre'"/>
  </div>
</template>

<script setup>
  import { ref, onMounted } from 'vue';
  import { useModalStore } from "../../stores/modal_store.js";
  import { useScreens } from '../../composables/screens.js';

  const modalStore = useModalStore();
  const { isMobile } = useScreens();

  onMounted(() => {
    if (isMobile.value) {
      modalStore.updateModal({
        componentName: 'ModalsMobileWarning',
        closingForbidden: true,
      });
    }
  });

  const selectedGraph = ref('popularity');
  const selectGraph = (graph) => {
    selectedGraph.value = graph;
  };

  const buttonClasses = (graphKey) => {
    let classes = 'button m-rx1';
    if (selectedGraph.value === graphKey) classes += ' selected';
    return classes;
  }
</script>