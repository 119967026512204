<template>
  <img :class="`avatar ${size}`" :src="src" />
</template>

<script setup>
  import { computed } from 'vue';
  import { useFantasyStore } from '../../stores/fantasy_store.js';
  const fantasyStore = useFantasyStore();

  const { user, size } = defineProps(['user', 'size']);
  const league = computed(() => fantasyStore.league);

  const src = computed(() => {
    return `https://sleepercdn.com/avatars/${user.avatar || league.value.avatar}`;
  });
</script>

<style scoped>
  .avatar {
    height: 40px;
    width: 40px;
    border-radius: 20px;
  }
  .avatar.small {
    height: 20px;
    width: 20px;
    border-radius: 20px;
  }
</style>