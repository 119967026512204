<template>
  <div class="t-24 fw-7"> Warning </div>
  <div class="t-16 fw-3 m-tx6"> Sorry, this page is not viewable on mobile devices. </div>
  <a class="button m-tx6" @click="goBack"> Take me back </a>
</template>

<script setup>
  const goBack = () => {
    history.back();
  };
</script>