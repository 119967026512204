<template>
  <div 
    class="post-it" 
    :style="{ top: `${top}px`, right: `${right}px`, display: display }"
    @click="close"
  >
    <div class="close"
      v-if="destroyable"
      @click="close"
    >&#10007</div>
    <div>{{ text }}</div>
  </div>
</template>

<script setup>
  import { ref } from 'vue';
  const { text, top, right, destroyable } = defineProps({
    text: String,
    top: {
      type: Number,
      default: 0,
    },
    right: {
      type: Number,
      default: 0,
    },
    destroyable: {
      type: Boolean,
      default: true,
    },
  });

  const display  = ref(null);
  const close = () => {
    if (destroyable) {
      display.value = 'none';
    }
  };
</script>