import { defineStore } from "pinia";

export const useToastStore = defineStore("ToastStore", {
  state: () => {
    return {
      visible: false,
      text: null,
      type: null
    };
  },

  actions: {
    setSuccessToast({ text }) {
      const textOverride = 'Success!';
      this.setToast({text: (text || textOverride), type: 'success'});
    },
    setErrorToast({ text }) {
      const textOverride = 'Uh oh, something went wrong...';
      this.setToast({text: (text || textOverride), type: 'error'});
    },

    setToast({ text, type }) {
      this.visible = true;
      this.text = text;
      this.type = type;
      setTimeout(this.clearToast, 2800); // Must be consistently slightly less than time value in toast.scss
    },

    clearToast() {
      this.visible = false;
      this.text = null;
      this.type = null;
    },
  },
});