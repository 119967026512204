<template>
  <MoviesChartsPieTemplate
    v-if="chartData"
    id="genre-analysis-chart"
    :chart-data="chartData"
    :chart-options="chartOptions"
  />
</template>

<script setup>
  import { useMovieCharts } from '../../../composables/movie_charts.js';
    
  const metadata = { dataLimit: 20 };
  const { chartData, chartOptions } = useMovieCharts('genre', metadata);
</script>
