<template>
  <Bar
    :id="props.id"
    :options="props.chartOptions"
    :data="props.chartData"
    :style="props.chartStyle"
  />
</template>

<script setup>
  import { Bar } from 'vue-chartjs'
  import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'
  
  ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

  const props = defineProps(['id', 'chartOptions', 'chartData', 'chartStyle']);
</script>
