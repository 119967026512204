<template>
  <div class="t-28 fw-7 onyx">Bill Splitter</div>
  <button class="button m-tx6" @click="openUploadModal">
    <span class="material-symbols-outlined">upload</span>
    <span>Upload</span>
  </button>
  <div class="bill-splitter m-tx4">
    <div class="f-v w-80p">
      <div class="bill-splitter-tabs">
        <div
          v-for="(tab, tabIdx) in tabs"
          ref="tabsRef"
          :class="`bill-splitter-tab ${tabClasses(tabIdx)}`"
          @click="selectTab(tabIdx)"
        >
          <div class="f-sb f-ac h-100p p-lx2 p-rx2">
            <span v-if="displayTitles" :class="`tab-title ${tabTitleClasses}`">{{ tab.title }}</span>
            <div v-else></div>
            <span v-if="tabs.length > 1" class="material-symbols-outlined tab-close" @click.stop="removeTab(tabIdx)"> x </span>
          </div>
        </div>
        <div
          class="bill-splitter-tab add-new"
          @click="addTab"
        >
          <span class="material-symbols-outlined"> add </span>
        </div>
      </div>
      <div class="separator bs-below w-100p"></div>
    </div>
    <BillSplitterTab />
  </div>
</template>

<script setup>
  import { ref, computed, watch, onMounted } from 'vue';
  import { useBillSplitterStore } from '../../stores/bill_splitter_store';
  import { useModalStore } from "../../stores/modal_store.js";
  import { useScreens } from '../../composables/screens';

  const billSplitterStore = useBillSplitterStore();
  const modalStore = useModalStore();
  const { isMobile } = useScreens();

  const tabs = computed(() => billSplitterStore.tabs);
  const selectedTabIdx = computed(() => billSplitterStore.selectedTabIdx);

  const tabClasses = (tabIdx) => {
    const classes = [];
    if (tabIdx === selectedTabIdx.value) {
      classes.push('selected');
      if (tabIdx === 0) classes.push('no-before');
    } else {
      classes.push('unselected');
    }
    return classes.join(' ');
  };

  // Tab management
  const selectTab = (tabIdx) => {
    billSplitterStore.selectTab(tabIdx);
  };
  const addTab = () => {
    billSplitterStore.addTab();
  };
  const removeTab = (tabIdx) => {
    billSplitterStore.removeTab(tabIdx);
  };

  // Tab Sizing
  const tabsRef = ref(null);
  const tabWidth = ref(0);
  const numTabs = computed(() => tabs.value.length);
  const displayTitles = computed(() => tabWidth.value > 70);
  const tabTitleClasses = computed(() => `max-w-x${Math.floor(tabWidth.value / 4 * 0.6)}`);
  const assessTabSize = () => {
    setTimeout(() => {
      const sampleTab = tabsRef.value[0];
      tabWidth.value = sampleTab.offsetWidth;
    }, 10);
  };
  watch(numTabs, assessTabSize);

  // Upload
  const openUploadModal = () => {
    modalStore.updateModal({
      componentName: 'BillSplitterModalsUpload',
    });
  };

  onMounted(() => {
    if (isMobile.value) {
      modalStore.updateModal({
        componentName: 'ModalsMobileWarning',
        closingForbidden: true,
      });
    }
    assessTabSize();
  });
</script>