<template>
  <div class="t-28 fw-7 onyx">{{ movie.title }}</div>
  <BaseBreadcrumbs :crumbs="[{ path: '/movies', label: 'Movie Club Log' }]" class="m-tx4" />
  <div :class="`m-tx4 p-rx4 ${isMobile ? 'f-v' : 'f'}`">
    <div class="f-ac">
      <MoviesPoster
        :movie="movie"
        :size="isMobile ? 'w185' : 'w342'"
      />
    </div>
    <div :class="`p-rx1 m-tx4 m-lx4 m-rx4 ${isMobile ? 'w-80p' : 'h-x128 w-x84 of-y-s'}`">
      <div class="m-bx8" v-if="movie.viewing.date">
        <div class="t-18 fw-6">Date of Viewing</div>
        <div class="t m-lx4 m-tx4">{{ movie.viewing.date }}</div>
      </div>
      <div class="m-bx8" v-if="movie.viewing.proposer">
        <div class="t-18 fw-6">Proposer</div>
        <div class="t m-lx4 m-tx4">{{ movie.viewing.proposer }}</div>
      </div>
      <div class="t-18 fw-6">Overview</div>
      <div class="t m-lx4 m-tx4">{{ movie.overview }}</div>
      <div class="t-18 fw-6 m-tx8">Original Language</div>
      <div class="t m-lx4 m-tx4">{{ movie.original_language }}</div>
      <div class="t-18 fw-6 m-tx8">Release Date</div>
      <div class="t m-lx4 m-tx4">{{ movie.release_date }}</div>
      <div class="t-18 fw-6 m-tx8">Cast</div>
      <div
      v-for="(actor, i) in movie.cast"
      :class="`t m-lx4 ${i === 0 ? 'm-tx4' : ''}`"
      >{{ actor.name }}</div>
      <a class="button m-tx4 m-bx4" :href="`/movies/${movie.id}/edit`">Edit</a>
    </div>
  </div>
</template>

<script setup>
  import { computed } from 'vue';
  import { useScreens } from '../../composables/screens';

  const { isMobile } = useScreens();
  const { movie } = defineProps(['movie']);
</script>