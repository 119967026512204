import { ref, onMounted } from 'vue';

export function useScroll(container, items) {
  const containerHeight = ref(null);
  const containerScrollHeight = ref(null);
  const focusedRef = ref(null);

  const getSizeOfContainerAndPositionsOfItems = () => {
    items.forEach((item) => {
      const el = item.ref.value.$el;
      item.top = el.offsetTop;
      item.bottom = el.offsetTop + el.offsetHeight;
    });
    containerHeight.value = container.value.offsetHeight;
    containerScrollHeight.value = container.value.scrollHeight;
    return items;
  };

  const onScroll = () => {
    const scrollTop = container.value.scrollTop;
    const scrollBottom = scrollTop + containerHeight.value;
    const scrollPadding = 100; // If we're within this number of the edge of the scroll, just count it as the edge
    if (scrollTop < scrollPadding) {
      // This is just the beginning
      focusedRef.value = items[0].ref.value.$el.id;
    } else if (scrollBottom > containerScrollHeight.value - scrollPadding) {
      // This is the full scroll limit
      focusedRef.value = items[items.length - 1].ref.value.$el.id;
    } else {
      // This is everywhere in between
      const middleOfScreen = (scrollTop + scrollBottom) / 2;
      const middleTab = items.find(t => t.top < middleOfScreen && t.bottom > middleOfScreen);
      if (middleTab) {
        focusedRef.value = middleTab.ref.value.$el.id 
      }
    }
  };

  const scrollTo = (item) => {
    const el = item.ref.value.$el
    const height = item.bottom - item.top;
    if (height > containerHeight.value) {
      el.scrollIntoView({behavior: 'smooth'});
    } else {
      el.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center'
      });
    }
  };

  onMounted(() => {
    container.value.onscroll = onScroll;
    window.onresize = getSizeOfContainerAndPositionsOfItems;
    getSizeOfContainerAndPositionsOfItems();
  });

  return { focusedRef, scrollTo };
};