<template>
  <div class="f-ac f-jc m-tx4">
    <div class="t m-rx1">Show</div>
    <select v-model="lineType" class="input-select w-x32 ta-c" id="line-type-selector">
      <option value="points">Points</option>
      <option value="trends">Trendlines</option>
    </select>
    <div class="t m-lx1 m-rx1">for all</div>
    <select v-model="selectedPosition" class="input-select w-x28 ta-c" id="position-selector">
      <option value="all">Players</option>
      <option value="QB">QBs</option>
      <option value="RB">RBs</option>
      <option value="WR">WRs</option>
      <option value="TE">TEs</option>
    </select>
    <div class="t m-lx1 m-rx1">in games from</div>
    <select v-model="selectedRangeKey" class="input-select w-x48 ta-c" id="range-selector">
      <option value="sos">the start of season</option>
      <option :value="3">the last 3 weeks</option>
      <option :value="5">the last 5 weeks</option>
      <option :value="10">the last 10 weeks</option>
    </select>
  </div>
  <Line
    id="players-trends"
    :data="data"
    :options="options"
    class="m-x8 p-x8 m-bx8 bg-white br-4"
  />
</template>

<script setup>
  import { ref, computed } from 'vue';

  import { useFantasyStore } from '../../../stores/fantasy_store.js';
  const fantasyStore = useFantasyStore();

  import { useChartMethods } from '../../../composables/chart_methods.js'; 
  const { chartColors, getTrendlinePoints } = useChartMethods();

  import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js'
  import { Line } from 'vue-chartjs';
  ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

  const props = defineProps(['players']);

  // Weeks In Order
  const orderedWeeksAndYears = computed(() => {
    const weeksAndYears = [];
    fantasyStore.leagues.toReversed().map(l => l.season).forEach((year, yearIdx) => {
      const finalWeek = yearIdx === fantasyStore.leagues.length - 1 ? fantasyStore.nflState.week : 18;
      for (let week = 1; week <= finalWeek; week += 1) {
        const weekAndYear = { week, year };
        weeksAndYears.push(JSON.stringify(weekAndYear));
      }
    });
    return weeksAndYears;
  });

  // Players To Display
  const selectedPosition = ref('all');
  const players = computed(() => {
    return props.players.filter((player) => {
      if (selectedPosition.value === 'all') {
        return player.metrics;
      } else {
        return player.metrics && player.position === selectedPosition.value;
      }
    });
  });

  // Time Range to Display
  const selectedRangeKey = ref('sos');
  const selectedRange = computed(() => {
    if (selectedRangeKey.value === 'sos') {
      return orderedWeeksAndYears.value.filter((way) => {
        return JSON.parse(way).year === fantasyStore.nflState.season;
      });
    } else {
      const lastIdx = orderedWeeksAndYears.value.length - 1;
      return orderedWeeksAndYears.value.slice(lastIdx - selectedRangeKey.value, lastIdx + 1);
    }
  })

  // Data
  const lineType = ref('points');
  const labels = computed(() => {
    return selectedRange.value.map((way) => {
      return `Week ${JSON.parse(way).week}`;
    })
  });
  const datasets = computed(() => {
    const showTrendlines = lineType.value === 'trends';
    return players.value.flatMap((player, playerIdx) => {
      const data = selectedRange.value.map((way) => {
        const weekAndYear = JSON.parse(way);
        const stats = player.metrics[weekAndYear.year][weekAndYear.week].stats
        if (stats) return stats.stats.pts_half_ppr;
      });
      const literalDataset = {
        label: player.full_name,
        data,
        borderColor: chartColors[playerIdx],
        backgroundColor: chartColors[playerIdx],
        showLine: !showTrendlines,
      };
      const allDatasetsForPlayer = [literalDataset];
      if (showTrendlines) {
        const trendLinePoints = getTrendlinePoints(data);
        const trendlineDataset = {
          label: `${player.full_name} [Trendline]`,
          data: trendLinePoints,
          borderColor: chartColors[playerIdx],
          backgroundColor: chartColors[playerIdx],
          pointRadius: 0,
        };
        allDatasetsForPlayer.push(trendlineDataset);
      }
      return allDatasetsForPlayer;
    });
  });
  const data = computed(() => {
    return {
      labels: labels.value,
      datasets: datasets.value,
    };
  });

  // Options
  const options = computed(() => {
    return {
      responsive: true,
      maintainAspectRatio: true,
      spanGaps: true,
      scales: {
        y: {
          beginAtZero: true,
        },
      },
    }
  });
</script>
