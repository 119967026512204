<template>
  <div class="t-28 fw-7 onyx">Polls</div>
  <a href="/polls/new" class="button m-tx6 w-x32"> Create New Poll </a>
  <div class="h-80p of-y-s m-tx6">
    <div v-for="poll in props.polls" :class="pollClasses">
      <div class="f-sb f-ac m-x4">
        <div class="f-v">
          <div class="t-16 fw-6">{{ poll.title }} </div>
          <div class="t-14 fw-4 dark-gray m-tx2"> {{ poll.created_at }} </div>
        </div>
        <div v-if="poll.winners.length > 0" class="t-14 fw-5 dark-gray f-ac">
          <span class="material-symbols-outlined"> trophy </span>
          <span v-if="poll.winners.length === 1"> {{ poll.winners.map(o => o.name).join(", ") }} </span> 
          <span v-else> {{ `x${poll.winners.length}` }} </span> 
        </div>
      </div>
      <div class="f-sb m-bx4 m-lx4 m-rx4">
        <a :href="`/polls/${poll.id}/poll_votes/new`" :class="buttonClasses(poll)"> Vote </a>
        <a :href="`/polls/${poll.id}`" :class="buttonClasses(poll, true)"> Results </a>
        <a @click="openUnvoteModal(poll)" :class="buttonClasses(poll, true)"> Unvote </a>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { computed } from 'vue';
  import { useModalStore } from '../../stores/modal_store.js';
  import { useScreens } from '../../composables/screens.js';
  const modalStore = useModalStore();
  const props = defineProps(['polls']);
  const { isMobile } = useScreens();

  const pollClasses = computed(() => {
    let classes = 'br-4 b-1-onyx b-s m-bx6'
    if (isMobile.value) {
      return classes + ' w-x80';
    }
    return classes + ' w-x100';
  });

  const buttonClasses = (poll, disabledIfNoVotes) => {
    let classes = 'button';
    if (isMobile.value) {
      classes += ' w-x16';
    } else {
      classes += ' w-x24';
    }
    if (poll.votes.length === 0 && disabledIfNoVotes) classes += ' disabled';
    return classes
  };

  const openUnvoteModal = (poll) => {
    modalStore.updateModal({
      componentName: 'PollsModalsVoteRemover',
      data: { poll },
    });
  };
</script>