<template>
  <div class="item">
    <div class="f-sb">
      <div class="t-14">{{ itemDisplay }}</div>
      <span class="material-symbols-outlined t-14 white c-p" @click="removeItem"> close </span>
    </div>
    <div class="separator bg-white m-tx2 m-bx2"></div>
    <div class="f-sb">
      <div v-for="handle in presetHandles" :key="`handle-${handle}`" class="handle" draggable="true" @dragstart="onDragStart($event, handle)">
        <div class="t-14">{{ `${handle.toFixed(2)}x` }}</div>
      </div>
    </div>
    <div class="f-sb m-tx1">
      <div class="f" v-if="numPeople > 4">
        <div class="t-14 m-l4 m-r4 m-t4">{{ numPeople }} way split: </div>
        <div class="handle" draggable="true" @dragstart="onDragStart($event, handleFromNumPeople)">
          <div class="t-14">{{ `${handleFromNumPeople.toFixed(2)}x` }}</div>
        </div>
      </div>
      <div v-else></div>
      <div class="f">
        <input type="number" class="input-text h-12 w-x6" v-model="customHandleNumerator">
        <div class="t-14 m-l4 m-r4 m-t4">/</div>
        <input type="number" class="input-text h-12 w-x6" :min="1" v-model="customHandleDenominator">
        <div class="t-14 m-l4 m-r4 m-t4">:</div>
        <div :class="`handle ${customHandleIsDraggable ? '' : 'disabled'}`" :draggable="customHandleIsDraggable" @dragstart="onDragStart($event, customHandle)">
          <div class="t-14">{{ customHandleIsDraggable ? `${customHandle.toFixed(2)}x` : '' }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { ref, computed } from 'vue';
  import { useBillSplitterStore } from '../../stores/bill_splitter_store';

  const { itemIdx } = defineProps(['itemIdx']);
  const billSplitterStore = useBillSplitterStore();

  const item = computed(() => billSplitterStore.selectedTab.items[itemIdx]);
  const itemDisplay = computed(() => {
    const formattedPrice = billSplitterStore.currencyFormat.format(item.value.price);
    return `${item.value.name}: ${formattedPrice}`;
  });
  const removeItem = () => {
    billSplitterStore.removeItem(itemIdx);
  };

  // // Handles
  // Presets
  const presetHandles = [1, 2, 0.5, (1/3), 0.25];
  // Handle Derived From Num People
  const numPeople = computed(() => billSplitterStore.selectedTab.people.length);
  const handleFromNumPeople = computed(() => 1 / numPeople.value);
  // Custom Handle
  const customHandleNumerator = ref(1);
  const customHandleDenominator = ref(1);
  const customHandleIsDraggable = computed(() => customHandleDenominator.value > 0);
  const customHandle = computed(() => customHandleNumerator.value / customHandleDenominator.value );

  const onDragStart = (e, handle) => {
    const data = { name: item.value.name, price: item.value.price, perc: handle };
    e.dataTransfer.clearData();
    e.dataTransfer.setData("text/plain", JSON.stringify(data));
  }
</script>