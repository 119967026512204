<template>
  <div class="theater-container">
    <div v-for="asset in assets" class="theater-piece">
      <img :src="asset.img" class="image">
      <img 
        v-if="currentlyPlayingAudioKey === asset.key" 
        :src="pauseButton" 
        class="audio-button"
        @click="triggerAudio(asset.key)"
      >
      <img 
        v-else 
        :src="playButton" 
        class="audio-button"
        @click="triggerAudio(asset.key)"
      >
    </div>
  </div>
</template>

<script setup>
  import { ref, computed } from 'vue';
  import ttmnAud from '../../../../assets/audio/ttmn.mp3';
  import ocvAud from '../../../../assets/audio/ocv.mp3';
  import ttmnImg from '../../../../assets/images/ttmn.jpeg';
  import ocvImg from '../../../../assets/images/ocv.jpeg';
  import playButton from '../../../../assets/images/play-button.svg';
  import pauseButton from '../../../../assets/images/pause-button.svg';

  const currentlyPlayingAudio = ref(null);
  const currentlyPlayingAudioKey = ref(null);

  const assets = computed(() => {
    return [
      { key: 'ttmn', aud: ttmnAud, img: ttmnImg },
      { key: 'ocv', aud: ocvAud, img: ocvImg },
    ];
  });

  const triggerAudio = (key) => {
    if (currentlyPlayingAudio.value) {
      currentlyPlayingAudio.value.pause();
      currentlyPlayingAudio.value = null;
      if (currentlyPlayingAudioKey.value !== key) {
        playAudio(key);
      } else {
        currentlyPlayingAudioKey.value = null;
      }
    } else {
      playAudio(key);
    }
  };

  const playAudio = (key) => {
    currentlyPlayingAudioKey.value = key;
    const aud = assets.value.find(f => f.key === key).aud;
    currentlyPlayingAudio.value = new Audio(aud);
    currentlyPlayingAudio.value.play();
  };
</script>