<template>
  <div class="f-jc m-tx6">
    <button :class="`button m-rx1 ${groupingKey === 'round' ? 'selected' : ''}`" @click="() => groupingKey = 'round'">By Round</button>
    <button :class="`button ${groupingKey === 'pick_no' ? 'selected' : ''}`" @click="() => groupingKey = 'pick_no'">By Pick</button>
  </div>
  <Scatter
    :data="data"
    :options="options"
    class="m-x8 p-x8 m-bx8 bg-white br-4"
  />
</template>

<script setup>
  import { ref, computed } from 'vue';
  import { Scatter } from 'vue-chartjs';
  import { Chart as ChartJS, LinearScale, PointElement, LineElement, Tooltip, Legend } from 'chart.js';
  ChartJS.register(LinearScale, PointElement, LineElement, Tooltip, Legend);
  const props = defineProps(['data']);

  const colors = ['#0BD2D3', '#F990E8', '#2FDEA1', '#E0D0C1', '#776871', '#d0d0d0', '#FFCB77', '#FFFFFF'];
  const groupingKey = ref('round');

  const dataByUserId = computed(() => {
    const users = {};
    props.data.forEach((datum) => {
      const userId = datum.user?.user_id || 0;
      if (!users[userId]) users[userId] = [];
      users[userId].push(datum);
    });
    return users;
  });

  const data = computed(() => {
    const datasets = Object.keys(dataByUserId.value).map((userId, idx) => {
      const dataForUser = dataByUserId.value[userId];
      const plotPoints = dataForUser.map((datum) => {
        return { x: datum.pick[groupingKey.value], y: datum.totalPoints, name: datum.player.full_name };
      });
      return {
        label: dataForUser[0].user?.display_name || '[missing user]', // Same name for all, just pick the first
        fill: false,
        backgroundColor: colors[idx],
        borderColor: '#000000',
        data: plotPoints,
        pointRadius: 10,
        pointHoverRadius: 12,
      }
    });
    return { datasets };
  });

  const options = computed(() => {
    return {
      responsive: true,
      maintainAspectRatio: true,
      scales: {
        x: {
          display: true,
          title: {
            display: true,
            text: groupingKey.value === 'round' ? 'Draft Round' : 'Draft Pick',
            font: { size: 14, weight: 'bold' },
          },
          ticks: groupingKey.value === 'round' ? { stepSize: 1 } : { stepSize: null },
        },
        y: {
          display: true,
          title: {
            display: true,
            text: 'Total Points Since Draft',
            font: { size: 14, weight: 'bold' },
          },
        },
      },
      plugins: {
        tooltip: {
          callbacks: {
            beforeLabel: (tooltipItem) => {
              const dataset = data.value.datasets[tooltipItem.datasetIndex];
              const datum = dataset.data[tooltipItem.dataIndex];
              const groupingDesc = groupingKey.value === 'round' ? `Round ${datum.x}` : `Pick ${datum.x}`;
              return `${datum.name}\n${groupingDesc}\n${datum.y} Points Since Draft`;
            },
          },
        },
      },
    }
  });
</script>