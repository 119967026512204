<!-- When analyzingFullDraft, we should continue to render the loading button
even after the first round of data is fetched for the first league. Find a way! -->
<template>
  <div class="f-v f-ac f-jc h-100p w-100p br-8 p-x1 bg-seafoam-light">
    <div class="t fw-5 text-wrap">{{ player.full_name }}</div>
    <div class="t text-wrap">{{ user?.display_name || '[missing user]' }}</div>
    <div v-if="statsSinceDraft" class="m-tx2 w-90p bg-white b-1-seafoam p-2 br-4">
      <div class="t-10 fw-5 ta-c">Stats Since Draft</div>
      <div class="f-sb w-100p">
        <div class="t-10"># Games:</div>
        <div class="t-10">{{ statsSinceDraft.totalGames }}</div>
      </div>
      <div class="f-sb w-100p">
        <div class="t-10">Total Points:</div>
        <div class="t-10">{{ statsSinceDraft.totalPoints }}</div>
      </div>
      <div class="f-sb w-100p">
        <div class="t-10">Avg Points:</div>
        <div class="t-10">{{ statsSinceDraft.avgPoints }}</div>
      </div>
    </div>
    <button v-else :class="`button m-tx1 ${fetchingStatsLocally || fetchingStatsGlobally ? 'loading' : ''}`" @click="fetchStats">
      Get Stats
    </button>
  </div>
</template>

<script setup>
  import { ref, computed } from 'vue';
  import { useFantasyStore } from "../../stores/fantasy_store.js";
  const fantasyStore = useFantasyStore();

  const props = defineProps(['pick', 'draft', 'isFetchingStats']);
  
  const player = computed(() => fantasyStore.players[props.pick.player_id]);
  const user = computed(() => fantasyStore.users.find(user => user.user_id === props.pick.picked_by));
  const metrics = computed(() => player.value.metrics);

  // Seasons To Analyze
  const seasonsSinceDraft = computed(() => fantasyStore.seasonsSinceDraft(props.draft));
  
  // Fetching Stats
  const fetchingStatsGlobally = computed(() => props.isFetchingStats);
  const fetchingStatsLocally = ref(false);
  const fetchStats = () => {
    fetchingStatsLocally.value = true;
    fantasyStore.fetchPlayerMetrics({ playerId: props.pick.player_id, metricsType: 'stats' }).then(() => {
      fetchingStatsLocally.value = false;
    })
  };

  // Stats
  const statsSinceDraft = computed(() => fantasyStore.statsForPlayerForSeasons(player.value, seasonsSinceDraft.value));
</script>