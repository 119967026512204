<template>
  <div class="t-28 fw-7 onyx"> {{ props.poll.title }} </div>
  <BaseBreadcrumbs :crumbs="[{ label: 'Polls', path: '/polls' }]" class="m-tx4" />
  <div class="w-80p b-1-black br-4 f-v f-ac m-tx6">
    <div class="t-16 fw-4 ta-c m-tx8 m-bx3"> {{ voterText }} </div>
    <div class="t-20 fw-5 ta-c m-tx8 m-bx4"> {{ introText }} </div>
    <div v-for="(winner, idx) in props.poll.winners" class="ta-c">
      <div v-if="idx > 0" class="t-16 fw-4 m-tx2"> and </div>
      <div class="t-20 fw-5 m-tx2"> {{ winner.name}} </div>
    </div>
    <Bar
      id="poll-results-chart"
      :options="chartOptions"
      :data="chartData"
      :style="chartStyle"
      class="m-tx6 m-bx6"
    />
    <div class="f m-bx6">
      <div :class="roundIdxUpdateClasses(-1)" @click="updateRoundIdx(-1)"> {{ '<' }} </div>
      <div class="t m-lx4 m-rx4"> {{ `Round ${roundIdx + 1}` }} </div>
      <div :class="roundIdxUpdateClasses(1)" @click="updateRoundIdx(1)"> {{ '>' }} </div>
    </div>
  </div>
</template>

<script setup>
  import { Bar } from 'vue-chartjs';
  import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js';
  import { ref, computed } from 'vue';
  import { useScreens } from '../../composables/screens.js';
  
  ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)
  const props = defineProps(['poll']);
  const { isMobile } = useScreens();

  const voterText = computed(() => {
    const names = props.poll.votes.map(vote => vote.voter_name);
    if (names.length > 1) names[names.length - 1] = `and ${names[names.length - 1]}`;
    const separator = names.length > 2 ? ', ' : ' ';
    return `With votes from ${names.join(separator)}...`;
  });
  const introText = computed(() => {
    return `The winner is${props.poll.winners.length > 1 ? ' a tie between' : ''}`
  });

  const roundIdx = ref(0);

  const chartOptions = computed(() => {
    return {
      responsive: true,
      scale: {
        ticks: {
          precision: 0
        },
      },
    };
  });
  const chartData = computed(() => {
    let options = {};
    props.poll.options.forEach(o => options[o.id] = { name: o.name, count: 0, active: false });
    props.poll.round_summaries[roundIdx.value].forEach((vote) => {
      options[vote.id].count += 1;
      if (vote.active) {
        options[vote.id].active = true;
      }
    })
    const data = Object.values(options);
    return {
      labels: data.map(o => o.active ? o.name : `[${o.name}]`),
      datasets: [
        {
          label: 'Votes',
          data: data.map(o => o.count),
          backgroundColor: ['#4FFEC1', '#2FDEA1', '#0FBE81'],
        }
      ],
    };
  });
  const chartStyle = computed(() => {
    const height = isMobile.value ? '80px' : '150px';
    return { height, width: `${window.screen.width / 4}px`};
  });

  const roundIdxUpdateClasses = (val) => {
    let classes = 't c-p hover-highlight';
    if (val === -1 && roundIdx.value <= 0) {
      classes += ' disabled';
    } else if (val === 1 && roundIdx.value >= props.poll.round_summaries.length - 1) {
      classes += ' disabled';
    }
    return classes;
  };
  const updateRoundIdx = (val) => {
    if (val === -1 && roundIdx.value > 0) {
      roundIdx.value -= 1;
    } else if (val === 1 && roundIdx.value < props.poll.round_summaries.length - 1) {
      roundIdx.value += 1;
    }
  };
</script>