import { defineStore } from "pinia";
import axios from 'axios';

export const useTmdbStore = defineStore("TMDBStore", {
  state: () => {
    return {
      graphConfigs: {},
      selectedMovie: null,
      prefills: {},
    };
  },

  actions: {
    fetchGraphConfig(graph) {
      return new Promise((resolve) => {
        axios.get(`/movies/graphs_json?graph=${graph}`)
          .then(({ data }) => {
            this.graphConfigs[graph] = data;
          });
      });
    },

    selectMovie(movie) {
      this.selectedMovie = movie;
      if (this.selectedMovie) this.selectedMovie.viewing ||= {};
    },

    setPrefills(prefills) {
      this.prefills = prefills;
    },

    updateViewing({ key, value }) {
      this.selectedMovie.viewing[key] = value;
    },
  },
});