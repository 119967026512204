import { toRaw } from 'vue';
import { defineStore } from 'pinia';
import { useToastStore } from './toast_store.js';
import axios from 'axios';

export const useMidpointStore = defineStore("MidpointStore", {
  state: () => {
    return {
      map: null,
      locations: [],
      geographicalMidpoint: null,
    };
  },

  getters: {
    pinStyle: () => {
      return (pinType) => {
        const background = (pinType === 'midpoint') ? '#2FDEA1' : '#F990E8';
        return new google.maps.marker.PinElement({ background, borderColor: '#403F4C', glyphColor: '#FFFFFF' });
      }
    },
  },

  actions: {
    establishState({ key, value }) {
      this[key] = value;
    },

    addMarker({ title, position }) {
      return new Promise((resolve, _reject) => {
        const marker = new google.maps.marker.AdvancedMarkerElement({
          map: toRaw(this.map),
          title,
          position,
          content: this.pinStyle('default').element,
        });
        this.locations.push({ marker, title, position });
        resolve(true);
      })
    },

    performSearch(query) {
      axios.get(`/external_api/google_maps/get_coords?address=${encodeURIComponent(query)}`).then(({ data }) => {
        const firstResult = data.results[0];
        const toastStore = useToastStore();
        if (firstResult) {
          this.addMarker({
            title: query,
            position: firstResult.geometry.location
          }).then(() => {
            this.recenterMapAroundMarkers();
            this.updateGeographicalMidpoint();
          });
        } else {
          toastStore.setErrorToast({ text: 'Could not find any matching addresses...' });
        }
      });
    },

    recenterMapAroundMarkers() {
      // Get bounds of all existing markers
      const bounds = new google.maps.LatLngBounds();
      this.locations.forEach(({ marker }) => {
        const markerLatLng = new google.maps.LatLng(marker.position.lat, marker.position.lng);
        bounds.extend(markerLatLng);
      });
      // Pad it a bit so it's not fully zoomed in on one address
      const north = bounds.getNorthEast().lat();
      const east = bounds.getNorthEast().lng();
      const south = bounds.getSouthWest().lat();
      const west = bounds.getSouthWest().lng();
      const paddedNorthEast = new google.maps.LatLng(north + 0.005, east + 0.005);
      const paddedSouthWest = new google.maps.LatLng(south - 0.005, west - 0.005);
      bounds.extend(paddedNorthEast);
      bounds.extend(paddedSouthWest);
      // Fit the map to the new bounds
      toRaw(this.map).fitBounds(bounds);
    },

    updateGeographicalMidpoint() {
      if (this.locations.length > 1) {
        // If there is more than 1 location, we want to upsert the midpoint marker
        // Calculate the midpoint
        let totalLat = 0;
        let totalLng = 0;
        this.locations.forEach(({ marker }) => {
          totalLat += marker.position.lat;
          totalLng += marker.position.lng;
        });
        const medianLat = totalLat / this.locations.length;
        const medianLng = totalLng / this.locations.length;
        const midpointPosition = { lat: medianLat, lng: medianLng };
        axios.get(`/external_api/google_maps/get_address?lat=${medianLat}&lng=${medianLng}`).then(({ data }) => {
          const firstResult = data.results[0];
          let midpointAddress = firstResult ? firstResult.formatted_address : '[no available address]';
          // First, clear existing midpoint
          this.clearExistingGeographicalMidpoint();
          // Then, establish new one
          const marker = new google.maps.marker.AdvancedMarkerElement({
            map: toRaw(this.map),
            position: midpointPosition,
            title: midpointAddress,
            content: this.pinStyle('midpoint').element,
          });
          this.geographicalMidpoint = { marker, title: midpointAddress, position: midpointPosition };
        });
      } else {
        // If there is less than 1 location, remove the geographicalMidpoint from the DOM and state
        this.clearExistingGeographicalMidpoint();
      }
    },

    clearExistingGeographicalMidpoint() {
      if (this.geographicalMidpoint) {
        toRaw(this.geographicalMidpoint.marker).remove();
        this.geographicalMidpoint = null;
      }
    },

    removeLocation(idx) {
      // Remove the marker from the DOM
      const marker = this.locations[idx].marker;
      marker.remove();
      // Remove the location object from the list
      const newLocations = [...this.locations];
      newLocations.splice(idx, 1);
      this.locations = newLocations;
      // Update the midpoint and map bounds accordingly
      this.updateGeographicalMidpoint();
      this.recenterMapAroundMarkers();
    },
  },
});