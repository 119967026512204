<template>
  <div class="t-24 fw-6 m-tx4">{{ user.display_name }}</div>
  <div class="f-w w-90p m-tx4">
    <FantasyPlayerPreview v-for="player in players" :player="player" />
  </div>
  <div class="w-80p bg-onyx-light br-8 p-bx1 p-tx1">
    <FantasyChartsPlayersTrends :players="allPlayers"/>
  </div>
</template>

<script setup>
  import { computed } from 'vue';
  import { useFantasyStore } from '../../stores/fantasy_store.js';
  const fantasyStore = useFantasyStore();

  const user = computed(() => {
    return fantasyStore.users.find(user => user.user_id === fantasyStore.selectedView.user);
  });
  const roster = computed(() => {
    return fantasyStore.rosters.find(roster => roster.owner_id === fantasyStore.selectedView.user);
  });
  const allPlayers = computed(() => roster.value.players.map(playerId => fantasyStore.players[playerId]));
  const players = computed(() => {
    const positions = { 'QB': [], 'RB': [], 'WR': [], 'TE': [] };
    allPlayers.value.forEach(player => positions[player.position].push(player));
    Object.values(positions).forEach((positionalList) => {
      positionalList.sort((a, b) => {
        return a.search_rank - b.search_rank;
      });
    });
    return [...positions['QB'], ...positions['RB'], ...positions['WR'], ...positions['TE']];
  });
</script>