export function useChartMethods() {
  // Colors
  const cyans = [[11, 210, 211], [21, 200, 201], [31, 190, 191]];
  const pinks = [[249, 144, 232], [239, 154, 222], [229, 164, 212]];
  const seafoams = [[47, 222, 161], [57, 212, 171], [67, 202, 181]];
  const sands = [[224, 208, 193], [214, 218, 203], [204, 228, 213]];
  const plums = [[119, 104, 113], [129, 114, 123], [139, 124, 133]];
  const grays = [[208, 208, 208], [218, 218, 218], [228, 228, 228]];
  const peachs = [[255, 203, 119], [245, 213, 129], [235, 223, 139]];
  const whites = [[255, 255, 255], [245, 245, 245], [235, 235, 235]];
  const chartColors = [0, 1, 2].flatMap((idx) => {
    return [cyans, pinks, seafoams, sands, plums, grays, peachs, whites].map((color) => {
      return `rgb(${color[idx][0]}, ${color[idx][1]}, ${color[idx][2]})`;
    });
  });

  // Trendlines
  const getTrendlinePoints = (data) => {
    // Data should be an array of numbers, null values are allowed
    // Step 1: Assemble X and Y values of all relevant data points
    const dataPoints = [];
    for (let idx = 0; idx < data.length; idx += 1) {
      if (data[idx]) dataPoints.push({ x: idx, y: data[idx] });
    }
    // Step 2: Calculate the mean of X and Y
    const meanX = dataPoints.reduce((sum, point) => sum + point.x, 0) / dataPoints.length;
    const meanY = dataPoints.reduce((sum, point) => sum + point.y, 0) / dataPoints.length;
    // Step 3: Calculate the slope
    let numerator = 0;
    let denominator = 0;
    for (let point of dataPoints) {
      numerator += (point.x - meanX) * (point.y - meanY);
      denominator += (point.x - meanX) ** 2;
    }
    const slope = numerator / denominator;
    // Step 4: Calculate the y-intercept
    const yIntercept = meanY - slope * meanX;
    // Step 5: Calculate the beginning and end points based on slope and y-intercept
    const trendlinePoints = [];
    for (let x = 0; x < data.length; x += 1) {
      let y = null;
      if (x === 0 || x === data.length - 1) {
        y = slope * x + yIntercept;
      }
      trendlinePoints.push(y);
    }
    return trendlinePoints;
  };

  // Standard Deviation
  const standardDeviation = (arr) => {
    // Calculate the mean
    const mean = arr.reduce((sum, value) => sum + value, 0) / arr.length;

    // Calculate the sum of the squared differences from the mean
    const squaredDifferences = arr.map(value => (value - mean) ** 2);
    const avgSquaredDifference = squaredDifferences.reduce((sum, value) => sum + value, 0) / arr.length;

    // Return the square root of the average squared differences
    return Math.sqrt(avgSquaredDifference);
  }

  return { chartColors, getTrendlinePoints, standardDeviation };
}