<template>
  <MoviesChartsBarTemplate
    v-if="chartData"
    id="movie-rating-chart"
    :chart-data="chartData"
    :chart-options="chartOptions"
    :chart-style="chartStyle"
  />
</template>

<script setup>
  import { computed } from 'vue';
  import { useMovieCharts } from '../../../composables/movie_charts.js';
    
  const { chartData, chartOptions } = useMovieCharts('rating');

  const chartStyle = computed(() => {
    return {
      height: '600px',
      width: `${window.screen.width / 1.5}px`,
    };
  });
</script>
