<template>
  <nav>
    <div class="header-logo" ref="flickerText">
      <div> William </div>
      <div> Johnson </div>
    </div>
    <div class="f">
      <div :class="`tab m-rx6${selectedTab === 'music' ? ' active' : ''}`" @click="selectTab('music')"> Music </div>
      <div :class="`tab m-rx6${selectedTab === 'about' ? ' active' : ''}`" @click="selectTab('about')"> About </div>
    </div>
  </nav>
  <div class="border"></div>
  <div class="resume-music-body">
    <template v-if="selectedTab === 'music'">
      <ResumeMusicSidebar
        v-if="!isMobile"
        :items="sidebarItems"
        :focused-item="focusedRef"
        :scroll-to="scrollTo"
      />
      <div class="resume-music-container" ref="container">
        <ResumeMusicSpotify id="spotify" ref="spotify"/>
        <div class="border w-80p m-tx2 m-bx2"></div>
        <ResumeMusicSoundcloud id="soundcloud" ref="soundcloud"/>
        <div class="border w-80p m-tx2 m-bx2"></div>
        <ResumeMusicAds id="ads" ref="ads"/>
        <div class="border w-80p m-tx2 m-bx2"></div>
        <ResumeMusicShortFilms id="films" ref="films"/>
        <div class="border w-80p m-tx2 m-bx2"></div>
        <ResumeMusicVideoGames id="games" ref="games"/>
        <div class="border w-80p m-tx2 m-bx2"></div>
        <ResumeMusicLiveTheater id="theater" ref="theater"/>
        <div class="border w-80p m-tx2 m-bx2"></div>
        <ResumeMusicMiscellaneous id="misc" ref="misc"/>
      </div>
    </template>
    <template v-else-if="selectedTab === 'about'">
      <ResumeMusicAbout />
    </template>
  </div>
</template>

<script setup>
  import { ref, onMounted } from 'vue';
  import { useScreens } from '../../../composables/screens.js';
  import { useScroll } from '../../../composables/scroll.js';

  // Sidebar Items
  const spotify = ref(null);
  const soundcloud = ref(null);
  const ads = ref(null);
  const films = ref(null);
  const games = ref(null);
  const theater = ref(null);
  const misc = ref(null);
  const container = ref(null);
  const sidebarItems = [
    { name: 'Spotify', ref: spotify, id: 'spotify' },
    { name: 'Soundcloud', ref: soundcloud, id: 'soundcloud' },
    { name: 'Advertising', ref: ads, id: 'ads' },
    { name: 'Short Films', ref: films, id: 'films' },
    { name: 'Video Games', ref: games, id: 'games' },
    { name: 'Theater', ref: theater, id: 'theater' },
    { name: 'Miscellaneous', ref: misc, id: 'misc' },
  ];
  const { focusedRef, scrollTo } = useScroll(container, sidebarItems);
  const { isMobile } = useScreens();

  // Tabs
  const selectedTab = ref('music');
  const selectTab = (val) => {
    selectedTab.value = val;
  };

  // Logo Flicker
  const flickerText = ref(null);
  let flickerOnIdx = 0;
  const flickerOnTimes = [4000, 100, 7000, 100];
  const setFlickerTimeout = (time) => {
    if (flickerText.value.classList.contains('flicker')) {
      setTimeout(() => {
        flickerOnIdx = (flickerOnIdx + 1) % flickerOnTimes.length;
        flickerText.value.classList.remove('flicker');
        setFlickerTimeout(flickerOnTimes[flickerOnIdx]);
      }, time);
    } else {
      setTimeout(() => {
        flickerText.value.classList.add('flicker');
        setFlickerTimeout(100);
      }, time);
    }
  };

  // Lifecycle
  onMounted(() => {
    setFlickerTimeout(flickerOnTimes[flickerOnIdx]);
  });
</script>