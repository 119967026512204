<template>
  <template v-if="isSplashPage">
    <div :class="splashPageClass">
      <router-view></router-view>
    </div>
  </template>
  <template v-else>
    <BaseNavbar />
    <BaseToast v-if="toastStore.visible"/>
    <BaseModal v-if="modalStore.componentName"/>
    <div class="container">
      <router-view v-bind="props"></router-view>
    </div>
  </template>
</template>

<script setup>
  import { useModalStore } from '../stores/modal_store.js';
  const modalStore = useModalStore();

  import { useToastStore } from '../stores/toast_store';
  const toastStore = useToastStore();

  const { props } = defineProps(['props']);
  const splashPageClass = props?.splashPageClass;
  const isSplashPage = !!splashPageClass;
</script>