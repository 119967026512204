<template>
  <div class="f-w w-80p m-tx4">
    <div
      class="fantasy-menu-option"
      @click="selectView({ trades: true })"
      :style="{ 'background-image' : seafoamGradient }"
    ><div class="t-32 fw-7">TRADES</div></div>
    <div
      class="fantasy-menu-option"
      @click="selectView({ drafts: true })"
      :style="{ 'background-image' : seafoamGradient }"
    ><div class="t-32 fw-7">DRAFTS</div></div>
    <div 
      v-for="user in users" 
      class="fantasy-menu-option" 
      @click="selectView({ user: user.user_id })"
      :style="{ 'background-image': cyanGradient }"
    ><div class="t-32 fw-7"> {{ user.display_name }} </div></div>
  </div>
</template>

<script setup>
  import { computed } from 'vue';
  import { useFantasyStore } from '../../stores/fantasy_store.js';
  const fantasyStore = useFantasyStore();

  // Derived from values in colors.scss
  const cyanGradient = `linear-gradient(to top right, #2FDEA180 0, #0BD2D3 20%, #0BD2D370 100%)`;
  const seafoamGradient = `linear-gradient(to top right, #0BD2D380 0, #2FDEA1 20%, #2FDEA170 100%)`;

  const users = computed(() => fantasyStore.users);

  const selectView = (view) => {
    fantasyStore.selectView(view);
  };
</script>