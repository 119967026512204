<template>
  <div class="movies-menu-container">
    <a 
      v-for="(link, idx) in links"
      class="poster-link"
      :href="link.path"
    >
      <div class="poster-child bg" :style="{ 'background-image': backgroundImages[idx] }"></div>
      <div class="poster-child label"> {{ link.label }} </div>
    </a>
  </div>
</template>

<script setup>
  import { computed } from 'vue';
  const props = defineProps(['posterPaths']);

  const backgroundImages = computed(() => {
    const gradientColors = ['#2FDEA1', '#F990E8', '#0BD2D3']; // seafoam, pink, and cyan in colors.scss
    return props.posterPaths.map((path, idx) => {
      const gradient = `linear-gradient(to bottom, ${gradientColors[idx]}70, ${gradientColors[idx]}90)`;
      const url = `url(http://image.tmdb.org/t/p/original${path})`;
      return `${gradient}, ${url}`;
    });
  });

  const links = [
    { path: '/movies', label: 'LOG' },
    { path: '/movies/graphs', label: 'GRAPHS' },
    { path: '/polls', label: 'POLLS' },
  ];
</script>
