<template>
  <div class="f">
    <div v-for="crumb in props.crumbs">
      <a :href="crumb.path" @click="onClick(crumb)" class="t-12 dark-gray no-ul c-p breadcrumb m-lx1">
        {{ '< ' + crumb.label }} 
      </a>
    </div>
  </div>
</template>

<script setup>
  const props = defineProps(['crumbs'])

  const onClick = (crumb) => {
    if (crumb.clickMethod) {
      crumb.clickMethod();
    }
  };
</script>

<style scoped lang="css">
  .breadcrumb:hover {
    filter: brightness(1.5);
  }
</style>