<template>
  <div id="map" :class="isMobile ? 'w-x70 h-x70' : 'w-x128 h-x128'"></div>
</template>

<script setup>  
  import { onMounted } from 'vue';
  import { Loader } from "@googlemaps/js-api-loader"
  import { useMidpointStore } from '../../stores/midpoint_store.js';
  import { useScreens } from '../../composables/screens.js';
  
  const midpointStore = useMidpointStore();
  const { isMobile } = useScreens();
  const { mapId } = defineProps(['mapId']);

  onMounted(() => {
    const apiKey = import.meta.env.VITE_GOOGLE_MAPS_API_KEY;
    if (apiKey) {
      console.log('Google Maps API Key successfully loaded...');
    } else {
      console.log('WARNING: Google Maps API Key not loaded...');
    }

    const loader = new Loader({ apiKey, version: "weekly" });

    loader
      .importLibrary('maps')
      .then(() => google.maps.importLibrary('marker'))
      .then(() => initMap())
      .catch((e) => {
        console.log('error', e);
      });
  });

  const initMap = () => {
    const defaultCenter = {lat: 40.7303084, lng: -73.8953973}
    const defaultZoom = 10.5;
    const mapOptions = { center: defaultCenter, zoom: defaultZoom, mapId };
    const map = new google.maps.Map(document.getElementById("map"), mapOptions);
    midpointStore.establishState({ key: 'map', value: map });
  };
</script>